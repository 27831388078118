import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogService } from '../dialog.service';
import { ImageModuleComponent } from '../image-module/image-module.component';
import { ReceiveOrderModalComponent } from '../receive-order-modal/receive-order-modal.component';

@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.scss']
})
export class PurchaseOrderDetailComponent implements OnInit {
  order_id:any={}
  order_data:any=[];
  invoice_data:any=[];

  show_save : any = 0;
  show_price : any = 0;


  constructor(public serve:DatabaseService,public route:ActivatedRoute,public editdialog:DialogService,public dialog: MatDialog,private router: Router,public dialog2:MatDialog,public toast: ToastrManager,) {
    this.route.params.subscribe( params => {
      console.log(params);
      this.order_id = params.id;
      console.log(this.order_id);
      if(this.order_id)
      {
        this.order_detail()
        this.invoice_item_detail();

      }

    });
  }

  ngOnInit() {
  }

  order_detail(){
    console.log(this.order_id);
    this.serve.fetchData({'id':this.order_id},"Organization/purchase_order_detail").subscribe((result=>
      {
        console.log(result);
        this.order_data=result;
        console.log(this.order_data);

      }))
    }

    invoice_item_detail()
    {
      this.serve.fetchData({'id':this.order_id},"Organization/recieved_order_detail").subscribe((result=>
        {
          console.log(result);
          this.invoice_data=result;
          console.log(this.invoice_data);
        }))

      }
      back(){
        window.history.go(-1)
      }

      onClick(){

        this.router.navigate(['/add-receive-order'],{ queryParams:{'id' : this.order_data.id}});

      }

      receiving_list()
      {

        this.router.navigate(['/receive-order-list'],{ queryParams:{'id' : this.order_data.id}});

      }

      openImage(image){
        console.log('test');

        const dialogRef = this.dialog2.open(ImageModuleComponent, {
          width: '500px',
          data: {
            type: 'receiving_image',
            image:image
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          console.log('The dialog was closed');

        });
      }

      view_receive_detail(id)
      {

        const dialogRef = this.dialog2.open(ReceiveOrderModalComponent, {
          width: '1300px',
          data: {
            id
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.order_detail();
          this. invoice_item_detail()

        });

      }



      calc(id,qty){

        console.log(id,qty);
        var index = this.order_data['order_item'].findIndex(row=>row.id === id)
        console.log(index);
        this.order_data['order_item'][index].amount=(this.order_data['order_item'][index].price)*this.order_data['order_item'][index].qty;
        console.log(this.order_data['order_item'][index].amount);

        this.order_data.order_item_qty=0;
        this.order_data.order_total=0;

        for(let i=0;i<this.order_data['order_item'].length;i++){

          this.order_data.order_item_qty=parseInt(this.order_data.order_item_qty)+parseInt(this.order_data['order_item'][i].qty);
          this.order_data.order_total=parseFloat(this.order_data.order_total)+parseFloat(this.order_data['order_item'][i].amount);
          console.log(this.order_data.order_item_qty);

        }

        console.log(this.order_data['order_item']);

        this.order_data.sub_total = (parseFloat(this.order_data.order_total?this.order_data.order_total:0) + parseFloat(this.order_data.packing?this.order_data.packing:0) + parseFloat(this.order_data.transport?this.order_data.transport:0)).toFixed(2);

        if(this.order_data.gstType == "cgst_gst"){

          this.order_data.cgst_amt = ((parseFloat(this.order_data.cgst) * parseFloat(this.order_data.sub_total))/100).toFixed(2) ;
          this.order_data.sgst_amt = ((parseFloat(this.order_data.cgst) * parseFloat(this.order_data.sub_total))/100).toFixed(2) ;
          console.log('cgst_amt',this.order_data.cgst_amt);
          console.log('sgst_amt',this.order_data.sgst_amt);
          this.order_data.order_grand_total = (parseFloat(this.order_data.sub_total?this.order_data.sub_total:0)+parseFloat(this.order_data.cgst_amt?this.order_data.cgst_amt:0)+parseFloat(this.order_data.sgst_amt?this.order_data.sgst_amt:0)+parseFloat(this.order_data.other?this.order_data.other:0)).toFixed(2);

        }else{
          this.order_data.igst_amt = ((parseFloat(this.order_data.igst) * parseFloat(this.order_data.sub_total))/100).toFixed(2) ;
          console.log('igst_amt',this.order_data.igst_amt);
          this.order_data.order_grand_total = (parseFloat(this.order_data.sub_total?this.order_data.sub_total:0)+parseFloat(this.order_data.igst_amt?this.order_data.igst_amt:0)+parseFloat(this.order_data.other?this.order_data.other:0)).toFixed(2);
        }



      }

      update_order(id)
      {
        console.log(id);
        var index = this. order_data['order_item'].findIndex(row=>row.id === id)
        console.log(index);
        console.log(this.order_data['order_item'][index]);


         this.order_data['order_item'][index]['pending_qty'] = this.order_data['order_item'][index]['qty'];
        this.serve.fetchData({'id':id,'order_item':this.order_data['order_item'][index],'total_qty':this.order_data.order_item_qty,'total_amount':this.order_data.order_total},'Organization/update_item')
        .subscribe((result)=>{
          console.log(result);
          if(result=='Success'){
            this.toast.successToastr('item Successfully Updated')

          }
          this.order_detail();


        })
      }

      print(): void
      {
          let printContents, popupWin;
          printContents = document.getElementById('print-section').innerHTML;
          popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
          popupWin.document.open();
          popupWin.document.write(`
          <html>
          <head>
          <title>Print tab</title>

          <style>

          body
          {
              font-family: 'arial';
          }
          .print-section
          {
              width: 1024px;
              background: #fff;
              padding: 15px;
              margin: 0 auto
          }


          .print-section table
          {
              width: 1024px;
              box-sizing: border-box;
              table-layout: fixed;
          }




          .print-section table tr table.table1 tr td:nth-child(1){width: 324px;}
          .print-section table tr table.table1 tr td:nth-child(2){width: 700px;}


          </style>

          </head>

          <body onload="window.print();window.close()">${printContents}</body>
          </html>`
          );
          popupWin.document.close();
      }



      update_price_order(id)
      {
        console.log(id);
        var index = this. order_data['order_item'].findIndex(row=>row.id === id)
        console.log(index);
        console.log(this.order_data['order_item'][index]);


        //  this.order_data['order_item'][index]['pending_qty'] = this.order_data['order_item'][index]['qty'];
        this.serve.fetchData({'id':id,'order_item':this.order_data['order_item'][index],'total_qty':this.order_data.order_item_qty,'total_amount':this.order_data.order_total,"sub_total":this.order_data.sub_total,"cgst_amt":this.order_data.cgst_amt,'igst_amt':this.order_data.igst_amt,'sgst_amt':this.order_data.sgst_amt,'order_grand_total':this.order_data.order_grand_total,'gstType':this.order_data.gstType},'Organization/update_item')
        .subscribe((result)=>{
          console.log(result);
          if(result=='Success'){
            this.toast.successToastr('item Successfully Updated')

          }
          this.order_detail();


        })
      }

      calc_order(id,price){

        console.log(id,price);
        var index = this.order_data['order_item'].findIndex(row=>row.id === id);
        console.log(index);
        this.order_data['order_item'][index].amount=(price)*this.order_data['order_item'][index].qty;
        console.log(this.order_data['order_item'][index].amount);

        this.order_data.order_total=0;

        for(let i=0;i<this.order_data['order_item'].length;i++){
          this.order_data.order_total=parseFloat(this.order_data.order_total)+parseFloat(this.order_data['order_item'][i].amount);
          console.log(this.order_data.order_total);

        }

        console.log(this.order_data['order_item'])

        this.order_data.sub_total = (parseFloat(this.order_data.order_total?this.order_data.order_total:0) + parseFloat(this.order_data.packing?this.order_data.packing:0) + parseFloat(this.order_data.transport?this.order_data.transport:0)).toFixed(2);

        if(this.order_data.gstType == "cgst_gst"){

          this.order_data.cgst_amt = ((parseFloat(this.order_data.cgst) * parseFloat(this.order_data.sub_total))/100).toFixed(2) ;
          this.order_data.sgst_amt = ((parseFloat(this.order_data.cgst) * parseFloat(this.order_data.sub_total))/100).toFixed(2) ;
          console.log('cgst_amt',this.order_data.cgst_amt);
          console.log('sgst_amt',this.order_data.sgst_amt);
          this.order_data.order_grand_total = (parseFloat(this.order_data.sub_total?this.order_data.sub_total:0)+parseFloat(this.order_data.cgst_amt?this.order_data.cgst_amt:0)+parseFloat(this.order_data.sgst_amt?this.order_data.sgst_amt:0)+parseFloat(this.order_data.other?this.order_data.other:0)).toFixed(2);

        }else{
          this.order_data.igst_amt = ((parseFloat(this.order_data.igst) * parseFloat(this.order_data.sub_total))/100).toFixed(2) ;
          console.log('igst_amt',this.order_data.igst_amt);
          this.order_data.order_grand_total = (parseFloat(this.order_data.sub_total?this.order_data.sub_total:0)+parseFloat(this.order_data.igst_amt?this.order_data.igst_amt:0)+parseFloat(this.order_data.other?this.order_data.other:0)).toFixed(2);
        }

      }
    }
