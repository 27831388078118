import { Component, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from '../../_services/DatabaseService';
// import { DialogComponent } from '../../dialog/dialog.component';
import { MatDialog, MatDatepicker } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../dialog.component';
// import { MastetDateFilterModelComponent } from 'src/app/mastet-date-filter-model/mastet-date-filter-model.component';
// import { SendmessageComponent } from 'src/app/master/karigar-data/sendmessage/sendmessage.component';
// import { SendNotificationComponent } from 'src/app/master/karigar-data/send-notification/send-notification.component';
import { Location } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-karigar-list',
  templateUrl: './karigar-list.component.html',
})
export class KarigarListComponent implements OnInit {

  karigar_list : any =[];
  skelton = new Array(10);
  loader = false;
  show_today;
  data:any = {};
  pagenumber:number=1;
  total_page:any; 
  count:any;
  data_not_found=false;
  constructor(public db: DatabaseService, public dialog: DialogComponent, public route: ActivatedRoute, public alrt: MatDialog, public location: Location) {

    this.karigarList();

  }


  ngOnInit() {

  }

  karigarList(){

    this.loader = true

    if(this.data.date_created){
      this.data.date_created =  moment(this.data.date_created).format("YYYY-MM-DD");
    }

    this.db.fetchData({"search":this.data,'page':this.pagenumber},"Karigar/GET_KARIGAR_LIST").subscribe((result)=>{
      console.log(result);
      this.karigar_list = result['data'];
      this.loader = false
      this.count=result['count']['total_karigar'];
      this.total_page  = Math.ceil(parseInt(this.count)/50);

    },error=>{
      console.log("eror");
      this.dialog.error("Something went wrong");
      this.loader = false;
    })

  }


  back(): void {
    console.log("location back method calss");
    console.log(this.location);
    this.location.back()
  }

  karigarsSatus(status,id){
    this.dialog.confirm('Change Status').then((result) => {
      console.log(result);
      if (result) {
        this.loader = true
        this.db.fetchData("", "Karigar/CHANGE_STATUS/" + status + "/" + id).subscribe((result) => {
          console.log(result);
          this.loader = false
    
          this.karigarList();
    
        }, error => {
          console.log("eror");
          this.dialog.error("Something went wrong");
          this.loader = false;
        })
      }
      else{
      console.log('no clicked');
      
      }
      
    })
 

  }

  deleteKarigar(id){
    this.dialog.delete('Karigar !').then((result) => {
      if (result) {
        this.loader = true
        this.db.fetchData("", "Karigar/DELETE_KARIGAR/" + id).subscribe((result) => {
          console.log(result);
          this.loader = false
          this.karigarList();

        }, error => {
          console.log("eror");
          this.dialog.error("Something went wrong");
          this.loader = false;
        })

      }
    });
  }

  exportAsXLSX(){
    this.loader=true;
    this.db.fetchData({"search":this.data},"Karigar/EXPORT_ARTISAN_EXCEL").subscribe((result)=>
    {
      
      console.log(result);
      if(result['status']='Success'){
        this.loader=false;
        document.location.href = this.db.myurl2+'uploads/Artisan.csv';
      }
      
  },error => {
    console.log("eror");
    this.dialog.error("Something went wrong");
    this.loader = false;
  })};
  




}

