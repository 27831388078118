import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-finish-good-report',
  templateUrl: './finish-good-report.component.html',
  styleUrls: ['./finish-good-report.component.scss']
})
export class FinishGoodReportComponent implements OnInit {
  data:any={};
  reportList:any;
  search:any={};
  constructor(public serve:DatabaseService) { }

  ngOnInit() {
    // this.getReportList();
    this.category();

    let date = new Date();
    console.log(date);

    this.search.date_from = moment(date).format('YYYY-MM-DD');
    console.log(this.search.date_from);

    // let todate = new Date();
    let todate =  date.setDate(date.getDate() + 10);
    this.search.date_to = moment(todate).format('YYYY-MM-DD');
    console.log(this.search.date_to);

    this.search.report_type = "expiry_date";

    this.getReportList();
  }
  refresh(){

  }

  getReportList(action:any='')
  {
    console.log(action);

    if(action == "refresh")
    {
      this.search = {};
      let date2 = new Date();
      this.search.date_from = moment(date2).format('YYYY-MM-DD');
      let todate2 =  date2.setDate(date2.getDate() + 10);
      this.search.date_to = moment(todate2).format('YYYY-MM-DD');

      this.search.report_type = "expiry_date";

    }

    if(this.search.date_from)
    {
      this.search.date_from=moment(this.search.date_from).format('YYYY-MM-DD');
      console.log(this.search.date_from)
    }

    if(this.search.date_to)
    {
      this.search.date_to=moment(this.search.date_to).format('YYYY-MM-DD');
      console.log(this.search.date_to);

    }


    this.serve.fetchData({'search':this.search},"Organization/finishGoodStockReport").subscribe((result=>
      {
        console.log(result);

        this.reportList = result['data'];

      }))
    }

  submitDetail(){

  }

  category_data:any;
  category(){

    this.serve.fetchData({},"Organization/FG_categoryList").subscribe((result=>{
      console.log(result);
      this.category_data=result;
      console.log(this.category_data);


    })
    )

  }

  excel_data: any=[];
  exportAsXLSX():void {

    for(let i=0;i<this.reportList.length;i++){
      this.excel_data.push({'Category':this.reportList[i].category,'Product Name':this.reportList[i].product_name,'UOM':this.reportList[i].size,'Product Code':this.reportList[i].item_code,'Manufacturing Date':this.reportList[i].manufacturing_date,'Expiry Date':this.reportList[i].expiry_date,'Batch To':this.reportList[i].batch_no,'Stock':this.reportList[i].balance_stock});
    }
    this.serve.exportAsExcelFile(this.excel_data,'Finish Good Report');
    this.excel_data = [];

  }
}
