import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import {Router } from '@angular/router';

@Component({
  selector: 'app-warehouse-add',
  templateUrl: './warehouse-add.component.html',
  styleUrls: ['./warehouse-add.component.scss']
})
export class WarehouseAddComponent implements OnInit {
  state_list: any = [];
  countryList: any = [];
  district_list: any = [];
  city_list: any = [];
  city_area_list:any=[];
  pinCode_list: any = [];
  data: any = {};
  temp_state_list=[];
  temp_district_list=[];
  tmp_city_list=[];
  tmpsearch: any = {};
  active: any = {};
  login: any = {};
  search_state:any;
  search: any = {};
  asmList: any = [];
  tmp_userList: any = [];
  rsm: any = [];
  ass_user: any = [];
  loader:boolean=false;

  constructor(  public serve: DatabaseService,public toast : ToastrManager, public rout: Router,) {
    this.salesUserLIst();
    this.getCountryList();
    this.getStateList();
   }

  ngOnInit() {
    this.login=JSON.parse(localStorage.getItem('login'));
    console.log(this.login.data.id);
  }

  getCountryList() {
    this.serve.fetchData(0, "User/country_list").subscribe((response => {
        console.log(response);
        this.countryList = response['query']['country_name'];
    }));
}

getStateList() {
    this.serve.fetchData(0, "User/state_user_list").subscribe((response => {
        console.log(response);
        this.state_list = response['query']['state_name'];
        this.temp_state_list = response['query']['state_name'];

    }));
}

getDistrict() {
    this.serve.fetchData(this.data.state, "User/district_user_list").subscribe((response => {
        // console.log(response);
        this.district_list = response['query']['district_name'];
        this.temp_district_list = response['query']['district_name'];

    }));

    this.data.district='';
    this.data.city='';
    this.data.pincode='';
    this.data.cityArea='';

}

getCityList() {
    let value = { "state": this.data.state, "district": this.data.district }
    this.serve.fetchData(value, "User/city_user_list").subscribe((response => {
        console.log(response);
        this.city_list = response['query']['city'];
        this.tmp_city_list = response['query']['city'];



    }));

    this.serve.fetchData(value, "User/area_user_list").subscribe((response => {
        console.log(response);

        this.city_area_list = response['query']['area'];

    }));
}



getPinCodeList() {
    let value = { "state": this.data.state, "district": this.data.district, "city": this.data.city }
    this.serve.fetchData(value, "User/pincode_user_list").subscribe((response => {
        console.log(response);
        this.pinCode_list = response['query']['pincode'];
    }));
}
filter_state(state_name){
  console.log("filter_state method calls");
  console.log(state_name);
  console.log(this.temp_state_list);
  this.tmpsearch='';
  this.state_list = [];
  for (var i = 0; i < this.temp_state_list.length; i++) {
      state_name = state_name.toLowerCase();
      this.tmpsearch = this.temp_state_list[i].toLowerCase();
      if (this.tmpsearch.includes(state_name)) {
          this.state_list.push(this.temp_state_list[i]);
      }
  }
}

filter_district(district_name){
  console.log("filter_district method calls");
  console.log(district_name);
  console.log(this.temp_state_list);
  this.tmpsearch='';
  this.district_list = [];
  for (var i = 0; i < this.temp_district_list.length; i++) {
      district_name = district_name.toLowerCase();
      this.tmpsearch = this.temp_district_list[i].toLowerCase();
      if (this.tmpsearch.includes(district_name)) {
          this.district_list.push(this.temp_district_list[i]);
      }
  }
}
filter_city(city_name){
  console.log("filter_city method calls");
  console.log(city_name);
  console.log(this.tmp_city_list);
  this.tmpsearch='';
  this.city_list = [];
  for (var i = 0; i < this.tmp_city_list.length; i++) {
      city_name = city_name.toLowerCase();
      this.tmpsearch = this.tmp_city_list[i].toLowerCase();
      if (this.tmpsearch.includes(city_name)) {
          this.city_list.push(this.tmp_city_list[i]);
      }
  }
}
// user_assign_check(value,name,index, event) {
//   console.log(this.asmList);

//   if (event.checked) {
//       if (this.rsm.indexOf(this.asmList[index]['id']) === -1) {
//           console.log("in check");
//           this.rsm.push({id:value,name:name});
//       }
//   }
//   else {
//       for (var j = 0; j < this.asmList.length; j++) {
//           if (this.asmList[index]['id'] == this.rsm[j]) {
//               this.rsm.splice(j, 1);
//           }
//       }
//   }

//   this.ass_user = this.rsm
// }
salesUserLIst() {
  this.serve.fetchData({'access_level':1,'for':'warehouse'}, "User/sales_user_list").subscribe((result => {
      this.asmList = result['sales_user_list'];
      this.tmp_userList = this.asmList;
  }))
}

getItemsList(search) {
  this.asmList = [];
  console.log(this.asmList);
  for (var i = 0; i < this.tmp_userList.length; i++) {
      search = search.toLowerCase();
      this.tmpsearch = this.tmp_userList[i]['name'].toLowerCase();
      if (this.tmpsearch.includes(search)) {
          this.asmList.push(this.tmp_userList[i]);
      }
  }
}
get_namebyid(id){
    console.log(id);
    var index = this. asmList.findIndex(row=>row.id === id)
    console.log(index);
    console.log(this.asmList[index]);
    this.data.assign_user_name=this.asmList[index].name

    
}

submitDetail(){
  this.loader=true
  console.log(this.data);
  this.serve.fetchData({ "data": this.data, 'uid':this.login.data.id,}, "Warehouse/ADD_WAREHOUSE").subscribe((result => {
    // this.loader=false
   console.log(result);
   if(result['msg']==true){
    this.toast.successToastr(result['ErrorMessage']);
    this.loader=false
    this.rout.navigate(['/warehouse-list']);
  }
  else{
    this.toast.errorToastr(result['ErrorMessage']);
    this.loader=false

  }
  }));
}
}
