import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
// import { PearlService } from 'src/app/pearl.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/_services/DatabaseService';
import { sessionStorage } from 'src/app/localstorage.service';



import { PopGiftIssueModalComponent } from '../pop-gift-issue-modal/pop-gift-issue-modal.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';
import { DialogComponent } from 'src/app/dialog.component';

@Component({
  selector: 'app-pop-gift-detail',
  templateUrl: './pop-gift-detail.component.html'
})
export class PopGiftDetailComponent implements OnInit {
  skelton:any={};
  id:any={};
  popData:any={};
  loader:any;
  data_not_found=false;
  incoming_data_not_found=false;
  logIN_user: any;
  data:any = {};


  constructor(public dialog: MatDialog, public session: sessionStorage ,public serve: DatabaseService,public route:Router,public routes:ActivatedRoute,public toast:ToastrManager,public dialog2:DialogComponent,) {
    this.skelton = new Array(10);

    this.routes.params.subscribe( params => {
      console.log(params);
      this.id = params.id;
      console.log(this.id);
       this.logIN_user = JSON.parse(localStorage.getItem('user'));


    });

   }

  ngOnInit()
  {
    this.gift_detail();
  }


  popModal(type)
  {
    const dialogRef = this.dialog.open(PopGiftIssueModalComponent, {
      width:'768px',
      data:{
      type,
      id:this.id
      }});
      dialogRef.afterClosed().subscribe(result => {
        this.gift_detail()
      });

    }

    gift_detail()
    {
      this.loader=1;

      if(this.data.transfer_date){
        this.data.transfer_date = moment(this.data.transfer_date).format("YYYY-MM-DD");
      }

      this.serve.fetchData({"id":this.id, "search": this.data},"/PopGift/popDetail").subscribe((result=>{
        console.log(result);
        this.popData=result;
        console.log(this.popData);

        if(this.popData.incoming.length ==0 && this.popData.outgoing.length ==0){
          this.data_not_found=true;
          this.incoming_data_not_found==true
        }else{
          this.data_not_found=false;
          this.incoming_data_not_found==false

        }

      }))
      this.loader='';

    }
    deleteStock(uid){
      this.serve.fetchData({"id":uid},"/PopGift/deletePopGift").subscribe((result=>{
        console.log(result);
        if(result=true){

          this.toast.successToastr('Stock Successfully Deleted')
        }

        this.gift_detail();
    }))
  }
  exportAsXLSx(){
    this.loader=true;
    this.serve.fetchData({"id":this.id, "search": this.data},"PopGift/donwloadOutgoingHistoryExcel").subscribe((result)=>
    {
      
      console.log(result);
      if(result['msg']='Success'){
        this.loader=false;
        document.location.href = this.serve.myurl2+'uploads/outGoingHistoryReport.csv';
      }
      
  },error => {
    console.log("eror");
    this.dialog2.error("Something went wrong");
    this.loader = false;
  })};
  deleteOUtgoing(data) {
    console.log(data);
    
    this.dialog2.delete('Outgoing data !').then((result) => {
      if (result) {
         this.loader=true;
        this.serve.fetchData({'outgoing':data}, "PopGift/deletePopGiftOutGoingHistory").subscribe((result) => {
          if (result['msg']=='success') {
            this.loader=false;

            this.toast.successToastr('Outgoing Successfully Deleted...')
            this.gift_detail();

          }
          else{
           this.loader=false;

            this.toast.errorToastr('Something went wrong!...')

          }
        })
      }
    });
  }

}
