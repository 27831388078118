import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { sessionStorage } from '../localstorage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-scan-coupon-report',
  templateUrl: './scan-coupon-report.component.html',
  styleUrls: ['./scan-coupon-report.component.scss']
})
export class ScanCouponReportComponent implements OnInit {

  report:any=[];
  loader:any;
  data_not_found = false;
  search:any={};
  value:any={};
  today_date:Date;
  data:boolean=false
  data_coming:boolean=false


  constructor(public serve:DatabaseService,public dialog:DialogComponent, public dialogs: MatDialog,public session:sessionStorage) {
  this.today_date=new Date();

   }

  ngOnInit() {
    // this.getreport()
  }
  getreport()
  {
    if(this.search.date)
    {
      this.search.date=moment(this.search.date).format('YYYY-MM-DD');  
      console.log(this.search.date)  
    }
    // this.data = true;
    this.loader=true;
    this.serve.fetchData(0,"Karigar/msgSuccess").subscribe((result=>
    {
      // this.loader=false;
      console.log(result);

      if(result['msg']=='sucess'){
        this.data_coming=true;
        this.get_statement()
      }
      else{
      this.loader=false;

      }
    }));
  }
  get_statement(){

    this.serve.fetchData(this.search,"Karigar/scanCouponReport").subscribe((result)=>
      {
     console.log(`result`, result);
     this.data= true;
     this.loader=false
     this.data_coming=false;

     this.report=result;
     console.log(`this.report`, this.report);
      if(this.report.length == 0)
      {
        this.data_not_found = true;
      }
      else
      {
        this.data_not_found = false;
      }
    },error => {
      console.log("eror");
      this.loader = false;
      this.dialog.error("Something Went Wrong please contact to Support..");
      this.refresh()
    });

  }

  refresh()
  {
    this.search={};
    this.report=[];
    this.data=false;
    this.data_coming=false
  }
  exportAsXLSx(){
    this.loader=true;
    this.serve.fetchData(this.search,"Karigar/donwloadscanCouponReportForExcel").subscribe((result)=>
    {
      
      console.log(result);
      if(result['msg']='Success'){
        this.loader=false;
        document.location.href = this.serve.myurl2+'uploads/CouponScanReport.csv';
      }
      
  },error => {
    console.log("eror");
    this.dialog.error("Something went wrong");
    this.loader = false;
  })};
  

}
