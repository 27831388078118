import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { sessionStorage } from '../localstorage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-artisan-statement',  
  templateUrl: './artisan-statement.component.html',
  styleUrls: ['./artisan-statement.component.scss']
})
export class 
ArtisanStatementComponent implements OnInit {
  excel_data: any = [];

  report:any=[];
  tmpReport:any=[];
  tmp: any = [];
  loader:any;
  data_not_found = false;
  search:any={};
  value:any={};
  LASTTRID:any='N';
  today_date:Date;
  data:boolean=false
  data_coming:boolean=false
  public onDate(event): void
  {
    this.value.TXNDATE=moment(event.value).format('YYYY-MM-DD');
    this.get_statement('');
  }

  constructor(public serve:DatabaseService,public dialog:DialogComponent, public dialogs: MatDialog,public session:sessionStorage) {
  this.today_date=new Date();

   }

  ngOnInit() {
    // this.getreport()
  }
  getreport(page)
  {
    if(this.search.date)
    {
      this.search.date=moment(this.search.date).format('YYYY-MM-DD');  
      console.log(this.search.date)  
    }
    // this.data = true;
    this.loader=true;
    this.serve.fetchData(0,"Karigar/msgSuccess").subscribe((result=>
    {
      // this.loader=false;
      console.log(result);

      if(result['msg']=='sucess'){
        this.data_coming=true;
        this.get_statement(page)
      }
      else{
      this.loader=false;

      }
    }));
  }
  get_statement(page){
    if(this.search.UTRNUMBER){

      this.loader=true
    }

    this.serve.fetchData({'search':this.search,'LASTTRID':page},"Karigar/getstatement").subscribe((result)=>
    {
     console.log(`result`, result);
     this.data= true;
     this.loader=false
     this.report=result['data'];
     this.tmpReport=result['data'];
     this.LASTTRID=result['LASTTRID'];
     console.log(`this.report`, this.report);
      if(this.report.length == 0)
      {
        this.data_not_found = true;
      }
      else
      {
        this.data_not_found = false;
      }
    },error => {
      console.log("eror");
      this.loader = false;
      this.dialog.error("Something Went Wrong please contact to Support..");
      this.refresh()
    });

  }

  changeLastdir(){
    this.LASTTRID='N';
  }

  refresh()
  {
    this.search={};
    this.report=[];
    this.data=false;
    this.data_coming=false;
    this.LASTTRID='N';
  }
  // exportAsXLSx(page){
  //   this.loader=true;
  //   this.serve.fetchData({'search':this.search,'LASTTRID':page},"Karigar/donwloadStatementCSV").subscribe((result)=>
  //   {
      
  //     console.log(result);
  //     if(result['msg']='Success'){
  //       this.loader=false;
  //       document.location.href = 'https://apps.abacusdesk.com/vurabau/api/uploads/statement.csv';
  //     }
      
  // },error => {
  //   console.log("eror");
  //   this.dialog.error("Something went wrong");
  //   this.loader = false;
  // })};

  exportAsXLSX(): void {
    for (let i = 0; i < this.report.length; i++) {
      this.excel_data.push({ 'Amount': this.report[i].AMOUNT, 'Transaction Date': this.report[i].TXNDATE,'Transaction ID': this.report[i].TRANSACTIONID,'Transaction No.': this.report[i].UTRNUMBER,'Remark': this.report[i].REMARKS, });
    }
    this.serve.exportAsExcelFile(this.excel_data, 'TXN Statement');
  }
  getTransactionList(index, search) {
    console.log(search);
    if(search == undefined){
      search = "";
    }
    this.report = [];
    for (var i = 0; i < this.tmpReport.length; i++) {
      if (this.tmpReport[i][index] != null) {
        this.tmp = this.tmpReport[i][index];

      }
      if (this.tmpReport[i][index] == null) {
        this.tmp = '';

      }

      // this.tmp=this.tmp_UserList[i][index].toLowerCase();
      if (this.tmp.includes(search)) {
        this.report.push(this.tmpReport[i]);
      }
    }
  }
  


}
