import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-receive-order-modal',
  templateUrl: './receive-order-modal.component.html',
  styleUrls: ['./receive-order-modal.component.scss']
})
export class ReceiveOrderModalComponent implements OnInit {
  skelton:any;
  loader:any;
  data_not_found:boolean=false;
  qc_change:boolean=false;

  receiving_detail:any=[];
  dispatch:boolean=false;

  constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService,public toast : ToastrManager) {
    console.log(this.data);
    if(this.data.dispatch_data){
      this.dispatch=true;
    }
    else{
      this.viewreceivedetail()
    }
   }

  ngOnInit() {
  }


  viewreceivedetail()
  {


    this.serve.fetchData({'id':this.data.id},"Organization/recieved_order_detail").subscribe((result=>
      {
        console.log(result);
        this.receiving_detail=result

      }))


  }
  qc_update(event,qc,index){
    console.log(event);
    console.log(qc);
    this.qc_change=true
    if(event.checked==true){
      this.receiving_detail['item'][index].qc=true;
    console.log('in if means qc checked');
    
    }
    else{
      this.receiving_detail['item'][index].qc=false;

    }
    
  }
  update_status(item){
    console.log(item);
    this.serve.fetchData({'item':item},"Organization/updateQC").subscribe((result=>
      {
        console.log(result);
        if(result['msg']=='success'){
          this.toast.successToastr("Detail Updated Successfully...");

          this.dialog.closeAll();

        }
        // this.receiving_detail=result

      }))
  }
}
