import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { ReceiveProductionComponent } from '../receive-production/receive-production.component';

@Component({
  selector: 'app-production-plan-list',
  templateUrl: './production-plan-list.component.html',
  styleUrls: ['./production-plan-list.component.scss']
})
export class ProductionPlanListComponent implements OnInit {
  filter:any={};
  data:any = {}
  skelton:any={}
  productItemList = [];
  organizaton_data:any = [];
  state_data:any = [];
  loader = false;
  constructor(public serve: DatabaseService, public toast: ToastrManager, public dialog: MatDialog, public alert: DialogComponent) {
    this.skelton = new Array(10);
    this.filter.org_id = '1';
    this.filter.state = 'GOA';
    this.GET_PRODUCTION_LIST();
    this.organization_list();
    this.state_list();
  }
  
  ngOnInit() {
  }
  
  GET_PRODUCTION_LIST() {

    this.loader = true;
    
    this.serve.fetchData(this.filter, "Organization/production_plan_list").subscribe((result => {
      console.log(result);
      
      this.productItemList = result['production_plan'];
      
      setTimeout (() => {
        this.loader = false;
      }, 700);
      
    }))
    
  }

  delete_production(id){
    console.log("delete_production method calls");
    console.log(id);
    
    this.alert.delete('Production Plan !').then((result) => {
      if (result) {
        
        this.serve.fetchData({'id':id}, "Organization/deleteProductionPlan").subscribe((result) => {
          console.log(result);
          if (result['status'] == 'Success') {
            console.log('in success function');
            this.alert.success("Production Plan","Update Successfully");
            this.GET_PRODUCTION_LIST()
          }
          else {
            console.log('in failed function');
            this.alert.error("Something Went Wrong Please Try Again !");
          }
        })
      }
    });
    
    
  }
  
  organization_list() {
    
    this.serve.fetchData({}, "Organization/organization_list").subscribe((result => {
      console.log(result);
      this.organizaton_data = result;
      
    }))
    
  }
  
  state_list() {
    
    this.serve.fetchData({ 'id': this.filter.org_id }, "Organization/assignstate").subscribe((result => {
      console.log(result);
      this.state_data = result;
      
    }))
    
  }

  refresh()
  {
    console.log("REFRESH");
    
  }

  receveProductionPlan() {

    const dialogRef = this.dialog.open(ReceiveProductionComponent, {
      width: '700px',
      data: {
        
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      this.GET_PRODUCTION_LIST()

    });

  }
  
}
