import {Component, OnInit, Renderer2} from '@angular/core';
import { sessionStorage } from '../localstorage.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../dialog.component';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  notificationList:any;
  login_data:any = {};
  constructor(private renderer: Renderer2,public session:sessionStorage,public router:Router,public dialog:DialogComponent,public serve:DatabaseService) {
    this.login_data = this.session.getSession();
    this.login_data = this.login_data.value;
    this.login_data = this.login_data.data;
    console.log(this.login_data);
    this.getNotification();
  }

  ngOnInit() {
    this.serve.notification_source.subscribe(result=>{
      console.log(result);
      this.notificationList = result;
    });
  }

  status:boolean = false;
  toggleHeader() {
    this.status = !this.status;
    if(this.status) {
      this.renderer.addClass(document.body, 'nav-active');
    }
    else {
      this.renderer.removeClass(document.body, 'nav-active');
    }
  }

  status1:boolean = false;
  toggleNav() {
    console.log('toggleNav');

    this.status1 = !this.status1;
    if(this.status1) {
      this.renderer.addClass(document.body, 'active');
    }
    else {
      this.renderer.removeClass(document.body, 'active');
    }
  }

  logout()
  {
    this.dialog.confirm("Logout").then((result) => {
      if(result)
      {
        this.session.LogOutSession();
    this.router.navigate(['']);
      }
  });

  }


  getNotification(){
    this.serve.getNotification().subscribe((result=>{
        console.log(result);
        this.notificationList = result['data'];

    }));
  }
  // getNotification(){
  //   this.serve.fetchData({},"User/GET_PENDING_NOTIFiCATION").subscribe((result=>
  //     {
  //       console.log(result);
  //       this.notificationList = result['data'];

  //     }))
  // }

  onNotificationClick(id,val){
    this.serve.fetchData({},"User/UPDATE_READ_NOTIFICATION_STATUS/"+id).subscribe((result=>
      {
        console.log(result);
        this.getNotification();
        if(val != 'clear'){
          // this.router.navigate([]);
        }
      }))
  }

  not_status:boolean = false;
  newNotification(){

  this.not_status = !this.not_status;
  this.getNotification();

  }


}
