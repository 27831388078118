import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { sessionStorage } from 'src/app/localstorage.service';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html'
})
export class StatusModalComponent implements OnInit {
  // data:any={};
  login:any={};
  delivery_from:any;
  state_data:any=[];
  unassigned_state:any=[];
  delete_data:any=[];
  organization:any={};
  raw_data:any=[];
  login_data:any=[];
  raw_material:any=[];
  brand_data:any=[];
  tmpsearch:any={}
  warehouse_asign_user_id:any;
  warehouse_asign_user_name:any;
  warehouse_name:any;
  warehouse_state:any; 
  itemdata:any=[]
  loader:boolean=false;
  constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService,public session:sessionStorage,public toast : ToastrManager) {
    console.log(this.data);
    // if (this.data['data']['assigned_state'])
    // {
    //   this.state_data=this.data['data']['assigned_state'];
    // }
    console.log(this.state_data);
    this.organization=this.data['organization']
    console.log(this.organization);
    this.raw_data=this.data['data']
    console.log(this.raw_data);
    console.log(this.data.delivery_from);
    this.delivery_from=this.data.delivery_from;
    if(this.data.type=='sales_coordinator_approval'){
      console.log(this.data.itemdata);
      
      this.itemdata=this.data.itemdata
    }


  }

  ngOnInit() {
    this.login=JSON.parse(localStorage.getItem('login'));
    console.log(this.login.data.id);
    this.material();
    this.brand();
    this.category();


  }
  reason_reject:any
  order_status_change(reason,status){
    console.log(reason,status);

    this.serve.fetchData({'reason':reason,'status':status,'id':this.data.order_id,'action_by':this.login.data.id},"Order/orderstatus_change").subscribe((result=>{
      console.log(result);
    }))
    this.dialog.closeAll();

    // this.orderDetail();
  }


  delete_state()
  {
    console.log(this.unassigned_state);
    this.delete_data=this.unassigned_state['_pendingValue']
    console.log(this.delete_data);

  }


  stateUnassign()
  {
    this.serve.fetchData({'state_id':this.delete_data,},"Organization/state_unassign").subscribe((result=>
      {
        console.log(result);

      }))
      this.dialog.closeAll();

    }

    update_material()
    {
      this.serve.fetchData({'uid':this.login.data.id,'raw_material':this.raw_data},"Organization/update_raw_material").subscribe((result=>
        {
          console.log(result);

        }))
        this.dialog.closeAll();

      }

      material()
      {

        this.serve.fetchData({},"Organization/measurement_list").subscribe((result=>
          {
            console.log(result);
            this.raw_material=result;
          }))

        }

        brand(){

          this.serve.fetchData({},"Organization/brandlist").subscribe((result=>{
            console.log(result);
            this.brand_data=result;
            console.log(this.brand_data);

          })
          )
        }

        category_data:any;
        category(){

          this.serve.fetchData({},"Organization/categoryList").subscribe((result=>{
            console.log(result);
            this.category_data=result;
            console.log(this.category_data);
          })
          )

        }
        warehouse_list:any=[];
        warehouseList(){

          this.serve.fetchData(this.tmpsearch,"Warehouse/WAREHOUSE_LIST").subscribe((result=>{
            console.log(result);
            this.warehouse_list=result['result'];
            console.log(this.warehouse_list);

          })
          )
        }
        stockdata:any=[];
        checkstockwarehouse(warehouse_id){
          this.loader=true
          console.log(warehouse_id);
          this.serve.fetchData({'warehouseid':warehouse_id, 'item_data':this.itemdata},"Warehouse/getStockWarehouseWise").subscribe((result=>{
            console.log(result);
            this.loader=false
            this.stockdata=result['item_data'];
            console.log(this.stockdata);
            
          }))
          
        }
        order_status_change_coordinator(reason,status,Warehouse){
          console.log(reason,status);
         console.log(this.login);
         console.log(Warehouse);
         if(Warehouse){
           var index = this. warehouse_list.findIndex(row=>row.id === Warehouse)
           console.log(index);
           console.log(this.warehouse_list[index]);
           this.warehouse_asign_user_id=this.warehouse_list[index].user_id
           this.warehouse_asign_user_name=this.warehouse_list[index].user_name
           this.warehouse_name=this.warehouse_list[index].warehouse_name
           this.warehouse_state=this.warehouse_list[index].warehouse_state



         }
          this.serve.fetchData({'reason':reason,'status':status,'order_id':this.data.order_id,'sales_cor_id':this.login.data.id,'sales_cor_name':this.login.data.name,'warehouse_id':Warehouse,'warehouse_name':this.warehouse_name,'warehouse_state':    this.warehouse_state,'warehouse_asign_user_name':this.warehouse_asign_user_name,'warehouse_asign_user_id':this.warehouse_asign_user_id,},"Order/APPROVE_STATUS_BY_CODINATOR").subscribe((result=>{
            console.log(result);
            if(result['msg']=='success'){
              this.toast.successToastr("Order Status Updated Successfully...");
              this.dialog.closeAll();
            }
            else{
              this.toast.errorToastr("Order Status Updated Successfully...");
              this.dialog.closeAll();
            }
          }))
      
          // this.orderDetail();
        }

        order_status_change_finance(reason,status){
          console.log(reason,status);
         console.log(this.login);
      
          this.serve.fetchData({'reason':reason,'status':status,'order_id':this.data.order_id,'sales_finance_id':this.login.data.id,'sales_finance_name':this.login.data.name,},"Order/APPROVE_STATUS_BY_FINANCE").subscribe((result=>{
            console.log(result);
            if(result['msg']=='success'){
              this.toast.successToastr("Order Status Updated Successfully...");
              this.dialog.closeAll();
            }
            else{
              this.toast.errorToastr("Order Status Updated Successfully...");
              this.dialog.closeAll();
            }
          }))
      
          // this.orderDetail();
        }

      statusChangeofdr(reason,status){
          console.log(reason,status);
         console.log(this.login);
          this.serve.fetchData({'data':{'remark':reason,'status':status,'id':this.data.dr_id,'uid':this.login.data.id,'uname':this.login.data.name,}},"Distributors/updateCustomerRedeemStatus").subscribe((result=>{
            console.log(result);
            if(result['statusCode']==200){
              this.toast.successToastr("Status Updated Successfully...");
              this.dialog.closeAll();
            }
            else{
              this.toast.errorToastr(result['statusMessage']);
              this.dialog.closeAll();
            }
          }))
      
          // this.orderDetail();
        }
      }
