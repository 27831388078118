import { Component, OnInit } from '@angular/core';
import { slideToTop } from '../../router-animation/router-animation.component';
import { DatabaseService } from 'src/_services/DatabaseService';
import { Router } from '@angular/router';
import { DialogComponent } from 'src/app/dialog.component';
import * as moment from 'moment';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  animations: [slideToTop()]

})
export class UserAddComponent implements OnInit {

  tabActiveType: any = {};
  state_list: any = [];
  state: any = [];
  rsm_list: any;
  designationData: any;
  data: any = {};
  district_list: any = [];
  city_list: any = [];
  area_list: any = [];
  pinCode_list: any = [];
  isslected;
  user_type;
  usertype = true;
  basicdetails = false;
  userrole;
  active: any = {};
  sales_type: any = [];
  reporting_sales_type: any = [];
  rsm: any = [];
  ass_user: any = [];
  tmp_userList: any = [];
  search: any = {};
  tmpsearch: any = {};
  submit = false;
  loader: any;
  module_name: any = [];
  access: any = {};
  exist:boolean=false;
  userData:any;
  userId:any;

  constructor(public serve: DatabaseService, public rout: Router,public dialog: DialogComponent) {
    this.getStateList();
    this.get_sales_user_type();
    this.getUserDesignationlist();
    this.assign_users('');
    this.data.user_type = 'Sales User';
    this.data.user_role;
    this.userData = JSON.parse(localStorage.getItem('st_user'));
    console.log(this.userData);
    this.userId=this.userData['data']['id'];
  }

  tabActive(tab: any) {
    this.tabActiveType = {};
    this.tabActiveType[tab] = true;
  }



  ngOnInit() {
    // this.rsmassign();
    this.userType('MARKET')
  }

  toggleterritory(key, action) {
    console.log(action);
    console.log(key);

    if (action == 'open') { this.active[key] = true; }
    if (action == 'close') { this.active[key] = false; }

    console.log(this.active);
  }


  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) { event.preventDefault(); }

  }


  check_number() {
    if (this.data.mobileno.length == 10) {
      this.serve.fetchData({ "mobile": this.data.mobileno }, "User/check_user").subscribe((result => {
        if (result['exists']) {
          this.exist = true;
        }
        else {
          this.exist = false;
        }
      }))
    }
  }



  userType(usertype) {
    this.user_type = usertype.value
    console.log(this.user_type);

    if (this.user_type) {
      this.data.user_type = this.user_type;
      console.log(this.data.user_type);
      this.basicdetails = true;
    }
  }

  submitDetail() {
    this.loader = true;
    this.submit = true;
    // this.data.assignuser = this.ass_user;

    console.log(this.data);

    // return;
    this.data.uid = this.userId

    // if (this.data.userrole == '8')
    if (this.data.user_type == 'System User')
    this.data.accessValue = this.access

    if(this.data.joining_date)
    {
      this.data.joining_date=moment(this.data.joining_date).format('YYYY-MM-DD');
      console.log(this.data.joining_date)  

    }

    if(this.data.exit_date)
    {
      this.data.exit_date=moment(this.data.exit_date).format('YYYY-MM-DD');  
      console.log(this.data.exit_date)  
    }

    console.log(this.data);
    this.serve.fetchData(this.data, "User/add_user").subscribe((response => {
      console.log(response);
      if(response['msg']=="success"){

        this.dialog.success("User", "Added");
        this.loader = false;
        this.rout.navigate(['/sale-user-list']);
      }
      else{

        this.dialog.error("Something Went Wrong Please try again !");
        this.loader = false;
      }

    }));
  }


  getStateList() {
    console.log("addUser");
    this.serve.fetchData(0, "User/state_user_list").subscribe((response => {
      console.log(response);
      this.state = response['query'];
      this.state_list = this.state['state_name'];
      console.log(this.state_list);

    }));

  }

  getDistrict() {
    console.log(this.data.state);
    this.serve.fetchData(this.data.state, "User/district_user_list").subscribe((response => {
      // console.log(response);
      this.district_list = response['query']['district_name'];
      console.log(this.district_list);
    }));

  }

  getCityList() {
    console.log(this.data.district);
    let value = { "state": this.data.state, "district": this.data.district }
    this.serve.fetchData(value, "User/city_user_list").subscribe((response => {
      console.log(response);
      this.city_list = response['query']['city'];
      console.log(this.city_list);
    }));
  }

  getPinCodeList() {
    console.log(this.data.state, this.data.city, this.data.district);
    let value = { "state": this.data.state, "district": this.data.district, "city": this.data.city }
    this.serve.fetchData(value, "User/pincode_user_list").subscribe((response => {
      console.log(response);
      this.pinCode_list = response['query']['pincode'];
      console.log(this.pinCode_list);

    }));
  }

  get_sales_user_type() {
    // console.log(this.data.state,this.data.city,this.data.district);
    // let value={"state":this.data.state,"district":this.data.district,"city":this.data.city}
    this.serve.fetchData({}, "User/sales_type").subscribe((response => {
      console.log(response);
      console.log(response['sales']);

      this.sales_type = response['sales'];
      console.log(this.sales_type);

    }));
  }

  getreporting_users(user_type) {
    console.log(user_type);
    this.serve.fetchData({ 'user_type': user_type }, "User/reportingsales_type").subscribe((response => {
      console.log(response);


      this.reporting_sales_type = response['reportingsales_type'];
      console.log(this.reporting_sales_type);

    }));
  }

  assign_users(usertype) {
    this.serve.fetchData({ 'user_type': usertype }, "User/assign_users").subscribe((response => {
      console.log(response);
      this.rsm_list = response['assign_users'];
      console.log(this.rsm_list);
      this.tmp_userList = this.rsm_list;
    }));
  }


  //user assing function
  user_assign_check(index) {
    // this.userCheck = false;
    this.rsm.push(index);
    console.log(this.rsm);
    this.data.assignUser = [];

    console.log(index);
    this.data.assignUser.push({ id: this.rsm_list[index]['id'], name: this.rsm_list[index]['name'] });
    console.log(this.data.assignUser);
    console.log(this.data.assignUser.length);
    this.ass_user = this.rsm;
  }

  product_Brand(value, index, event) {
    if (event.checked) {
      this.rsm.push(value);
      console.log(this.rsm);
    }
    else {
      for (var j = 0; j < this.rsm_list.length; j++) {
        if (this.rsm_list[index]['id'] == this.rsm[j]) {
          this.rsm.splice(j, 1);
        }
      }
      console.log(this.rsm);
    }
    this.ass_user = this.rsm

  }

  getItemsList(search) {
    console.log(search);

    this.rsm_list = [];
    for (var i = 0; i < this.tmp_userList.length; i++) {
      search = search.toLowerCase();
      this.tmpsearch = this.tmp_userList[i]['name'].toLowerCase() + ' ' + this.tmp_userList[i]['role_name'].toLowerCase();
      if (this.tmpsearch.includes(search)) {
        this.rsm_list.push(this.tmp_userList[i]);
      }
    }
    console.log(this.rsm_list);

  }

  UserDesignationlist:any=[];
  getUserDesignationlist(){
    this.serve.fetchData({}, "User/user_list_dropdwn").subscribe((response => {
      console.log(response);
      this.UserDesignationlist=response['user_list_dropdwn'];
      // console.log(this.sales_type);

    }));
  }

  getdeginationRole(id) {
    console.log(this.data);

    console.log(id);

    this.designationData = [];
    for (var i = 0; i < this.UserDesignationlist.length; i++) {
      this.designationData.push(this.UserDesignationlist[i]);
    }
    console.log(this.designationData);

  }


}
