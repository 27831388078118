import { Component,Inject, OnInit } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService'
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { DialogComponent } from '../dialog.component';


@Component({
  selector: 'app-attendancemodal',
  templateUrl: './attendancemodal.component.html',
  styleUrls: ['./attendancemodal.component.scss']
})
export class AttendancemodalComponent implements OnInit {

  id:any;
  dist_list:any=[];
  total_distance:any;
  type:any;
  search:any={};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public navparams: ActivatedRoute,public serve: DatabaseService,public dialog:MatDialog,public alert:DialogComponent) {
    if(data.p){
      this.id=this.data.p.id;
      console.log(this.id);
      this.get_attendance_data();
    }
    if(data.type){
      this.type = data.type;
      console.log(this.type);
      this.getUserList('','');
    }
  }

  ngOnInit() {
  }

  get_attendance_data()
  {
    this.serve.fetchData({'id':this.id},"Attendance/getDistanceDetail").subscribe((result) => {
      console.log(result);
      this.dist_list=result['distance_data'];
      this.total_distance=result['total_distance'];
      console.log( this.dist_list);
      console.log( this.total_distance);


    });
  }

  UserDesignationlist:any=[];
  getUserDesignationlist(){
    this.serve.fetchData({}, "User/user_list_dropdwn").subscribe((response => {
      console.log(response);
      this.UserDesignationlist=response['user_list_dropdwn'];
    }));
  }

  get_all_designation()
  {
    this.serve.fetchData({'search': this.search }, "Attendance/" + 'getattendance_today')
    .subscribe(((result: any) => {
      console.log(result);
    }))

  }

  userlist:any;
  getUserList(val,type) {
    if(type=''){
      val = '';
    }
    this.serve.fetchData({search : val}, "Attendance/user_list").subscribe((result) => {
      console.log(result);

      this.userlist = result['user_list'];
      console.log(this.userlist);

    })

  }


  submitform(){
    console.log('formSubmitted');
    this.data.attendenceDate = moment(this.data.attendenceDate).format('YYYY-MM-DD');
    console.log(this.data);
    this.serve.fetchData(this.data, "Attendance/submitOldAttendence").subscribe((response => {
      console.log(response);
      if(response['msg']=='success'){
        this.alert.success("Detail","Updated");
        this.dialog.closeAll();
      }
      if(response['msg']=='exist'){
        this.alert.error("All Detail are already exist for this date, you can't modify it!");
        // this.dialog.closeAll();
      }
    }));
  }

  startTime(evt){
    console.log(evt);
    console.log(this.data.start_time);


  }
}
