import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.scss']
})
export class AttendanceReportComponent implements OnInit {
  reportList:any;
  search:any = {};
  reportingdata:any;
  data:any = {};

  constructor(public serve:DatabaseService) { }

  ngOnInit() {

    // this.getUserList('','')

    // this.getReportList('');
  }

  userlist:any;
  getUserList(val,type) {
    if(type=''){
      val = '';
    }
    this.serve.fetchData({search : val}, "Attendance/user_list").subscribe((result) => {
      console.log(result);

      this.userlist = result['user_list'];
      console.log(this.userlist);

    })

  }

  getReportList(action:any='')
  {
    console.log(action);

    if(action == "refresh")
    {
      this.search = {};
      this.reportList =[];
      this.reportingdata = '';
      return
    }

    if(action == "search")
    {
      this.reportingdata.search = this.search;
    }


    this.serve.fetchData({'data':this.reportingdata},"Organization/getRmReport").subscribe((result=>
      {
        console.log(result);

        this.reportList = result['data']['executive_orders'];
        console.log(this.reportList);
      }))
    }

    submitDetail(f:NgForm){

      console.log(this.data);

      if(this.data.date_from)
      {
        this.data.date_from=moment(this.data.date_from).format('YYYY-MM-DD');
        console.log(this.data.date_from)
      }

      if(this.data.date_to)
      {
        this.data.date_to=moment(this.data.date_to).format('YYYY-MM-DD');
        console.log(this.data.date_to);

      }

      this.reportingdata = JSON.parse(JSON.stringify(this.data));

      this.serve.fetchData({'data':this.data},"Organization/getRmReport").subscribe((result=>
        {
          console.log(result);

          this.reportList = result['data']['executive_orders'];
          console.log(this.reportList);

        }))

      f.resetForm();
      this.data = {};

    }

    excel_data: any=[];
    exportAsXLSX():void {

      for(let i=0;i<this.reportList.length;i++){
        this.excel_data.push({'Created By':(this.reportList[i].created_by_type == 'Executive')?this.reportList[i].creator_name:this.reportList[i].dr_name,'Creator Type':(this.reportList[i].created_by_type == 'Executive')?this.reportList[i].designation:this.reportList[i].created_by_type,'Order For':this.reportList[i].company_name,'Customer Type':this.reportList[i].customer_type,'Order No':this.reportList[i].order_no,'Order Qty':this.reportList[i].qty,'Order Value':this.reportList[i].sub_total,'Status':this.reportList[i].order_status,'Order Type':this.reportList[i].type,'State':this.reportList[i].state,'Assigned to':(this.reportList[i].created_by_type == 'Executive')?this.reportList[i].reporting_manager:this.reportList[i].dr_reporting_manager});
      }
      this.serve.exportAsExcelFile(this.excel_data,'Network Report');
      this.excel_data = [];

    }

}
