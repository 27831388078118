import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService'
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../dialog.component';
import { MatDialog } from '@angular/material';
import { ImageModuleComponent } from '../image-module/image-module.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { sessionStorage } from '../localstorage.service';
import { AttendancemodalComponent } from '../attendancemodal/attendancemodal.component';

@Component({
  selector: 'app-attendance-new',
  templateUrl: './attendance-new.component.html',
  styleUrls: ['./attendance-new.component.scss']
})
export class AttendanceNewComponent implements OnInit {

  tmp: any = [];
  data: any = {};
  start_attend_time: string;
  loader: boolean=false;
  value: any = {};
  att_temp: any = [];
  data_not_foun: any = false;
  pagelimit: any = 50;
  skelton: any = {};
  data_not_found = false;
  today_date: Date;
  today_day:any;
  show: boolean = false;
  flag: number = 0;
  enableInput: boolean = false;
  newToday_date: string;
  logIN_user:any;
  uid:any;
  uname:any;
  pagenumber:any=1;
  total_page:any;
  count: any;
  start:any=0;
  page_limit:any=50
  yesterday_date = new Date();
  tab:any;
  login_data:any={};
  my_tab:any = 'my';
  countdata:any;
  asmList:any=[];
  tmp_attendancelist:any=[];
  searchdiv:any;
  
  constructor(public rout: Router, public serve: DatabaseService, public navparams: ActivatedRoute,public dialog: DialogComponent, public toast: ToastrManager,public dialogs: MatDialog,public dialog2: MatDialog, public session:sessionStorage) {
    this.login_data = this.session.getSession();
    this.login_data = this.login_data.value;
    this.login_data = this.login_data.data;
    console.log(this.login_data);
    this.skelton = new Array(10);
    this.today_date = new Date();
    this.today_day = this.today_date.getDay();
    console.log(this.today_day);
    if(this.today_day == 5){
      this.today_day = 'Friday'
    }
    
    
    switch(this.today_day) {
      case 0:{
        this.today_day='Sunday';
        break;
      }
      case 1:{
        this.today_day='Monday';
        
        break;
      }
      case 2:{
        this.today_day='Tuesday';
        
        break;
      }
      case 3:{
        this.today_day='Wednesday';
        
        break;
      }
      case 4:{
        this.today_day='Thursday';
        
        break;
      }
      case 5:{
        this.today_day='Friday';
        
        break;
      }
      case 6:{
        this.today_day='Saturday';
        
        break;
      }
      default:{
        break;
      }
    }
    
    console.log(this.today_day);
    this.tab=navparams['params']['_value']['tab'];
    console.log(this.tab);
    
    console.log(this.today_date);
    this.newToday_date = moment(this.today_date).format('YYYY-MM-DD')
    console.log(this.newToday_date);
    this.logIN_user = JSON.parse(localStorage.getItem('st_user'));
    console.log(this.logIN_user);
    this.uid = this.logIN_user['data']['id'];
    this.uname = this.logIN_user['data']['name'];
    
  }
  
  ngOnInit() {
    if(this.tab=='yesterday')
    {
      this.attendance_list('getAttendance', 2);
      
    }
    else
    {
      this.attendance_list('getattendance_today', 1);
      
    }
    this.salesUserLIst();
    
  }
  
  
  attendancelist: any = [];
  todayAttandanceexcel:any=[];
  show_today: boolean = true;
  count_1: any;
  count_2: any;
  
  // change_tab(fn_name, type) {
  //   this.attendancelist = [];
  //   // this.data={}
  //   this.attendance_list(fn_name, type);
  //   this.show_today = true;

  // }
  allAttandanceexcel:any=[]
  attendance_list(func_name, type) {
    
    if (Object.getOwnPropertyNames(this.data).length != 0) {
      // this.pagelimit = 0;
      this.attendancelist = [];
    }
    
    // if(this.tab=='yesterday')
    // {
    //   console.log("in yesterday block");
      
    //   this.yesterday_date.setDate(this.yesterday_date.getDate() - 1);
    //   console.log( this.yesterday_date);
    //   this.data.date_to=moment(this.yesterday_date).format('YYYY-MM-DD');
    //   this.data.date_from=moment(this.yesterday_date).format('YYYY-MM-DD');
    // }
    
    if (this.data.date_created)
    this.data.date_created = moment(this.data.date_created).format('YYYY-MM-DD');
    if (this.data.date_from)
    this.data.date_from = moment(this.data.date_from).format('YYYY-MM-DD');
    if (this.data.date_to)
    this.data.date_to = moment(this.data.date_to).format('YYYY-MM-DD');
    
    
    this.loader = true;
    this.serve.fetchData({'search': this.data,'uid':this.login_data.id,'access_level':this.login_data.access_level,'access_tab': this.my_tab }, "Attendance/" + func_name)
    .subscribe(((result: any) => {
      console.log(result);
      setTimeout(() => {
        this.loader = false;
        
      }, 100);
      
      this.countdata = result;
      
      this.attendancelist = func_name != 'getattendance_today' ? result['attendence_data'] : result['data'];
      this.tmp_attendancelist = func_name != 'getattendance_today' ? result['attendence_data'] : result['data'];
      this.allAttandanceexcel=result['attendence_data']
      this.todayAttandanceexcel=result['data']
      
      this.count = func_name != 'getattendance_today' ? result['total_no_of_attendence'] : '0';
      
      
      
      console.log(this.attendancelist);
      
      console.log(this.attendancelist[0]['date_created']);
      
      // console.log(tmpDay);
      
      for(let i = 0; i < this.attendancelist.length; i++){
        this.attendancelist[i].date_created_day = moment(this.attendancelist[i].date_created,'YYYY.MM.DD').format("dddd");
      }
      
      console.log(this.attendancelist);
      
      
      for (let i = 0; i < this.attendancelist.length; i++) {
        for(let j = 0; j < this.attendancelist[i].length; j++){
          if( this.attendancelist[i][j].stop_reading == "") {
            this.attendancelist[i][j].start_reading=parseInt(this.attendancelist[i][j].start_reading);
          }
          else{
            this.attendancelist[i][j].stop_reading=parseInt(this.attendancelist[i][j].stop_reading);
            this.attendancelist[i][j].start_reading=parseInt(this.attendancelist[i][j].start_reading);
          }
        }
      }
      console.log(this.attendancelist);
      
      this.att_temp = result;
      // if (type == 1) {
      //   this.count_1 = this.attendancelist.length;
      //   this.count_2 = result.count;
      //   this.show_today = true;
      // }
      // else {
      //   this.count_1 = result.count;
      //   this.count_2 = this.attendancelist.length;
      //   this.show_today = false;
      // }
      
      console.log(this.attendancelist);
      console.log('in');
      
      if (this.attendancelist.length == 0) {
        this.data_not_found = true;
      }
      else{
        this.data_not_found = false;
        
      }
      
    }))
    
    
    
    
  }


  attendancelist1:any =[];
  attendanceDate1:any =[];


  getReport() {
    this.show_today = false;
    if (this.data.date_created)
    this.data.date_created = moment(this.data.date_created).format('YYYY-MM-DD');
    if (this.data.date_from)
    this.data.date_from = moment(this.data.date_from).format('YYYY-MM-DD');
    if (this.data.date_to)
    this.data.date_to = moment(this.data.date_to).format('YYYY-MM-DD');
    this.loader = true;
    this.serve.fetchData({  'search': this.data,'uid':this.login_data.id,'access_level':this.login_data.access_level,'access_tab': this.my_tab , 'page':this.pagenumber,}, "Attendance/getAttendanceMonth")
    .subscribe(((result: any) => {
      this.loader=false
      this.attendancelist1 = result.attendence_data.attendence_data;
      this.attendanceDate1 = result.attendence_data.Date;
      this.count=result.attendence_data.count;
      this.total_page = Math.ceil(this.count/50);
      console.log('====================================');
      console.log( this.attendanceDate1);
      console.log('====================================');
    }))
    
    
    
    
  }




  get_designation()
  {
    this.serve.fetchData({'designation':this.data.designation}, 'Attendance/getattendance_today')
    .subscribe(res => {
      this.attendancelist=res;
      console.log( this.attendancelist);
      
    })
    
  }
  get_all_designation()
  {
    this.serve.fetchData({'designation':this.data.designation}, 'Attendance/getAttendance')
    .subscribe(res => {
      this.attendancelist=res;
      console.log( this.attendancelist);
    })
  }
  reset_attendance(id: any) {
    var value = this.dialog.reset_att().then((result) => {
      console.log(result);
      if (result) {
        this.serve.fetchData({ 'id': id }, 'Attendance/update_attendance')
        .subscribe(res => {
          console.log(res);
          this.attendance_list('getattendance_today', 1);
          this.dialog.success_att('Reset Done', 'Attendance has been updated.');
        }, err => {
          console.log(err);
          this.dialog.error('Something went wrong! Try Again ...');
        });
      }
    });
  }
  excelList:any=[]
  excel(func_name){
    this.serve.fetchData({ 'search': this.data,'uid':this.login_data.id,'access_level':this.login_data.access_level,'access_tab': this.my_tab }, "Attendance/" + func_name)
    .subscribe(((result: any) => {
      this.excelList = func_name != 'getattendance_today' ? result['attendence_data'] : result['data'];
      this.exportAsXLSX()
    }
    ));
    
  }
  excel_data: any = [];
  exportAsXLSX(): void {
    
    console.log(this.attendance_list);
    console.log(this.excelList);
    for (let i = 0; i < this.excelList.length; i++) {
      this.excel_data.push({ 'Date': this.excelList[i].date_created, 'User Name': this.excelList[i].name,  'Designation':this.excelList[i].designation_name,'Reporting Manager':this.excelList[i].reporting_manager, 'Attendance Status':this.excelList[i].attend_date && this.excelList[i].leave!='1'? 'Present' :this.excelList[i].leave=='1'? 'On Leave':this.excelList[i].sunday=='1'? 'Sunday':this.excelList[i].holiday=='1'? 'Holiday':'Absent','Work Type': this.excelList[i].work_type,'Start Time': this.excelList[i].start_time, 'Start Location': this.excelList[i].start_address, 'Stop Time': this.excelList[i].stop_time, 'Stop Location': this.excelList[i].stop_address, 'Start reading': this.excelList[i].start_reading && this.excelList[i].start_reading != ''  ?  parseInt(this.excelList[i].start_reading) :0 ,'Stop reading': this.excelList[i].stop_reading && this.excelList[i].stop_reading != '' ?  parseInt(this.excelList[i].stop_reading):0,'Checkin count': this.excelList[i].check_in_count,  'Travel': this.excelList[i].km_per_day , 'Expense': this.excelList[i].expense_amount && this.excelList[i].expense_amount != ''?parseInt(this.excelList[i].expense_amount):0 , 
    });
  }
  this.serve.exportAsExcelFile(this.excel_data, 'Attendance Sheet');
  this.excel_data=[];
  // this.attendancelist=[];
  
}


filter_attendance(data) {
  console.log(data);
  console.log(this.data);
  this.serve.fetchData({ data: this.data.name, date: moment(this.data.date_created).format('YYYY-MM-DD') }, "Attendance/getAttendance")
  .subscribe((result => {
    console.log(result);
    
    // this.attendancelist = result;
    console.log(result);
    this.attendancelist = result;
    console.log(this.attendancelist);
    //     for (let i = 0; i < this.attendancelist.length; i++) {
    // this.attendence[i]
    
    //     }
    console.log('in');
    if (this.data.name == '') {
      this.attendance_list('getAttendance', 2);
    }
  }))
}
// getItemsList(search) {
//   console.log(search);
//   // this.attendancelist = [];
//   console.log(this.attendancelist);

//   for (var i = 0; i < this.attendancelist.length; i++) {
//     // search = search.toLowerCase();
//     this.tmpsearch1 = this.attendancelist[i]['reporting_manager'];
//     console.log(this.tmpsearch1);

//     // if (this.tmpsearch1.includes(search)) {
//       console.log(search);
//       this.attendancelist.push(this.tmpsearch1[i]);
//     // }
//   }
//   console.log(this.attendancelist);
// }

tmpsearch1: any = {};

test(){
  console.log("in test function ");
  
}

updateReading(stopReading, attendenceID) {
  console.log(stopReading);
  console.log(attendenceID);
  
  
  this.serve.fetchData({ 'id': attendenceID, "stop_reading": stopReading,'uid':this.uid,'name':this.uname }, "/Attendance/update_stop_reading").subscribe((result) => {
    console.log(result);
  });
  
  
  this.show = false;
  this.flag = 0
  this.attendance_list('getAttendance', 2)
  
}



goTo(SendName,SendDate,type) {
  console.log(SendName,SendDate);
  
  if (type == 'checkin') {
    this.rout.navigate(['/checkin', { selectedUser: SendName,selectedDate: SendDate }]);
  }
  
  else if (type == 'primary_sale') {
    this.rout.navigate(['/order-list', { selectedUser: SendName,selectedDate: SendDate,'from':'attendence' }]);
  }
  
  else if (type == 'secondary_sale') {
    this.rout.navigate(['/secondary-order-list', { selectedUser: SendName,selectedDate: SendDate,'from':'attendence' }]);
  }
  
  else if (type == 'contractor_meet') {
    this.rout.navigate(['/contractor-meet', { selectedUser: SendName,selectedDate: SendDate }]);
  }
  
  else if (type == 'Expense') {
    console.log(SendName);
    console.log(SendDate);
    
    this.rout.navigate(['/expense-list', { selectedUser: SendName,selectedDate: SendDate }]);
  }
  
  else {
    
  }
  
}
imageModel(start_meter_image, stop_meter_image,self_image)
{
  const dialogRef = this.dialogs.open( ImageModuleComponent, {
    // width: '500px',
    data:{
      start_meter_image,
      stop_meter_image,
      self_image

    }});
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('The dialog was closed');
      
    });
    
  }
  
  conInt(val : any){
    return val = parseFloat(val).toFixed(2);  //function convert data into float then number
  }
  
  
  conInt2(val : any){
    return val = parseInt(val)                // function convert dataa into int
  }
  
  
  enable_error() {
    console.log("error function call");
    console.log(this.data);
    
    this.toast.errorToastr("Stop reading must be greater than Start reading");
    
  }
  
  
  reset_attendence(id){
    console.log("reset_attendence method calls");
    console.log(id);
    this.loader = true;
    this.serve.fetchData({ 'id': id}, "/Attendance/reset_attendence").subscribe((result) => {
      console.log(result);
      this.loader = false;
      if(result['msg'] == 'success'){
        this.dialog.success("Attendence", "Reset");
      }
      else{
        this.dialog.error("Something Went Wrong Please Try again later !");
      }
    });
    
    this.attendance_list('getattendance_today', 1)
    
  }
  refresh()
  {
    this.data={};
    if(this.show_today){
      console.log('inside today refresh');
      
      this.attendance_list('getattendance_today', 1);
    }
    else{
      this.getReport()
    }
    // this.attendance_list('getAttendance', 2);
    
  }
  attendancemodal(p)
  {
    
    const dialogRef = this.dialog2.open(AttendancemodalComponent, {
      panelClass: 'rightmodal',
      data:{
        p
      }});
      dialogRef.afterClosed().subscribe(result => {
        
      });
      
    }
    // send_id(id)
    // {
    //   console.log(id);
    
    //   this.rout.navigate(['/attendancemodal',{id}]);
    
    // }
    
    
    BackAttendance(p)
    {
      
      const dialogRef = this.dialog2.open(AttendancemodalComponent, {
        panelClass: 'rightmodal',
        data:{
          type:'backAttendance'
        }});
        dialogRef.afterClosed().subscribe(result => {
          this.attendance_list('getattendance_today', 1)
          
        });
        
      }
      salesUserLIst() {
        this.serve.fetchData({'access_level':2,'userType':'active','search':this.searchdiv}, "User/sales_user_list").subscribe((result => {
          this.asmList = result['sales_user_list'];
          
        }))
      }
      
      getItemsList(index, search) {
        console.log(search);
        if(search == undefined){
          search = "";
        }
        this.attendancelist = [];
        for (var i = 0; i < this.tmp_attendancelist.length; i++) {
          search = search.toLowerCase();
    
          search = search.toLowerCase();
          if (this.tmp_attendancelist[i][index] != null) {
            this.tmp = this.tmp_attendancelist[i][index].toLowerCase();
    
          }
          if (this.tmp_attendancelist[i][index] == null) {
            this.tmp = '';
    
          }
    
          // this.tmp=this.tmp_attendancelist[i][index].toLowerCase();
          if (this.tmp.includes(search)) {
            this.attendancelist.push(this.tmp_attendancelist[i]);
          }
        }
      }
      tmp_allattendancelist:any=[];
      getrmlist(index, search) {
        this.serve.fetchData({'uid':this.login_data.id,'access_level':this.login_data.access_level,'access_tab': this.my_tab }, "Attendance/getAttendance" )
    .subscribe(((result: any) => {
      console.log(result);
      this.tmp_allattendancelist = result['attendence_data'];
      console.log(this.tmp_allattendancelist);
      
     }
    ));

        console.log(search);
        if(search == undefined){
          search = "";
        }
        this.attendancelist = [];
        for (var i = 0; i < this.tmp_allattendancelist.length; i++) {
          search = search.toLowerCase();
    
          search = search.toLowerCase();
          if (this.tmp_allattendancelist[i][index] != null) {
            this.tmp = this.tmp_allattendancelist[i][index].toLowerCase();
    
          }
          if (this.tmp_allattendancelist[i][index] == null) {
            this.tmp = '';
    
          }
    
          // this.tmp=this.tmp_allattendancelist[i][index].toLowerCase();
          if (this.tmp.includes(search)) {
            this.attendancelist.push(this.tmp_allattendancelist[i]);
          }
        }
      }

      get_report_excel(){
        if (this.data.date_created)
        this.data.date_created = moment(this.data.date_created).format('YYYY-MM-DD');
        if (this.data.date_from)
        this.data.date_from = moment(this.data.date_from).format('YYYY-MM-DD');
        if (this.data.date_to)
        this.data.date_to = moment(this.data.date_to).format('YYYY-MM-DD');
        this.loader = true;
        this.serve.fetchData({  'search': this.data,'uid':this.login_data.id,'access_level':this.login_data.access_level,'access_tab': this.my_tab , }, "Attendance/getattendanceExcel")
        .subscribe(((result: any) => {
        console.log(result);
        if (result['msg']==true) {
          window.open(this.serve.myurl2+'/uploads/'+result['filename'])
          this.loader=false;
        }
        else{
          this.dialog.error("Something Went Wrong!...");
          this.loader=false;

        }
        }))
      }

 

}
