import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-change-scheme-status',
  templateUrl: './change-scheme-status.component.html',
  styleUrls: ['./change-scheme-status.component.scss']
})
export class ChangeSchemeStatusComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService) { 
    console.log(this.data);

  }

  ngOnInit() {
  }
  changeStatus()
  {
    
    this.serve.fetchData({'status':this.data.status,'id':this.data.id},"Organization/update_scheme_status").subscribe((result=>{
      console.log(result);
    }))
    this.dialog.closeAll();
    
  }
}
