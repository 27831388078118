import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { sessionStorage } from '../localstorage.service';

@Component({
  selector: 'app-warehouse-transfer-model',
  templateUrl: './warehouse-transfer-model.component.html',
  styleUrls: ['./warehouse-transfer-model.component.scss']
})
export class WarehouseTransferModelComponent implements OnInit {
  tmpsearch:any={};
  loader:boolean=false;
  item_list:any=[];
  transferdata:any={};
  logIN_user:any;
  uid:any;
  uname:any;
  constructor(public serve:DatabaseService,@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public session:sessionStorage,public toast : ToastrManager) { 
    this.warehouseList();
    this.logIN_user = JSON.parse(localStorage.getItem('st_user'));
    console.log(this.logIN_user);
    this.uid = this.logIN_user['data']['id'];
    this.uname = this.logIN_user['data']['name'];
  }

  ngOnInit() {
  }

  warehouse_list:any=[];
  warehouseList(){

    this.serve.fetchData(this.tmpsearch,"Warehouse/WAREHOUSE_LIST").subscribe((result=>{
      console.log(result);
      this.warehouse_list=result['result'];
      console.log(this.warehouse_list);

    })
    )
  }
  getwarehouseitem(warehouse){
    this.loader=true
    console.log(warehouse);
    
    this.serve.fetchData({'data':{'search':this.tmpsearch,'warehouse':warehouse}},"Organization/FG_list").subscribe((result=>{
      console.log(result);
      this.item_list=result;
      this.loader=false;

    })
    )
  }
  getstock(item){
  console.log(item);
  this.transferdata.current_stock=item.current_warehouse_stock;
  }
  validatecheck(input){
    console.log(input);

    if (parseInt(this.transferdata.current_stock) < parseInt(input))
    {
    this.toast.warningToastr("Transfer Stock Should be less than current stock!..");
        this.transferdata.transfer_stock=0;
    }
    else
    { 
        console.log('valid');
        
    }
  }
  save_data(){
    if(this.transferdata.warehouse_from){
      var index = this. warehouse_list.findIndex(row=>row.id === this.transferdata.warehouse_from)
      console.log(index);
      console.log(this.warehouse_list[index]);
      this.transferdata.warehouse_from_name=this.warehouse_list[index].warehouse_name
    }
    if(this.transferdata.warehouse_to){
      var index = this. warehouse_list.findIndex(row=>row.id === this.transferdata.warehouse_to)
      console.log(index);
      console.log(this.warehouse_list[index]);
      this.transferdata.warehouse_to_name=this.warehouse_list[index].warehouse_name
    }
    this.loader=true 
    this.serve.fetchData({'data':this.transferdata,'created_by_id':this.uid,'created_by_name':this.uname},"Warehouse/WAREHOUSE_STOCK_TRANSFER").subscribe((result=>{
      console.log(result);
      this.loader=false;
      if (result['msg']=='success') {
        this.toast.successToastr("Stock Transfered Successfully...");
        this.dialog.closeAll();
      }
      else if(result['msg']=='notPossible'){
        this.toast.errorToastr("Warehouse From and Warehouse To should not be same!..");

      }
      else{
        this.toast.errorToastr("Something Went Wrong....");

      }
    })
    )
  }
}
