import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { sessionStorage } from '../localstorage.service';
import { slideToTop } from '../router-animation/router-animation.component';

@Component({
  selector: 'app-coupan-add',
  templateUrl: './coupan-add.component.html',
  styleUrls: ['./coupan-add.component.scss'],
  animations: [slideToTop()]
})
export class CoupanAddComponent implements OnInit {

  skelton:any={};
  id:any={};
  coupanlist:any=[];
  loader:any;
  data_not_found=false;
  logIN_user: any;
  data:any={};
  product_list:any;
  login_data:any;
  search:any={};

  constructor(public dialog: MatDialog, public session: sessionStorage ,public serve: DatabaseService,public route:Router,public routes:ActivatedRoute,public alert: DialogComponent) {
    this.skelton = new Array(10);

    this.login_data = this.session.getSession();
   }

  ngOnInit()
  {
    this.getcoupanlist();
    this.get_production_data('');
  }

    getcoupanlist()
    {
      // this.loader=1;
      if(this.search.created_date)
      {
        this.search.created_date=moment(this.search.created_date).format('YYYY-MM-DD');
        console.log(this.search.created_date)
      }
      this.serve.fetchData({'search':this.search},"coupons/coupon_code_history").subscribe((result=>{
        console.log(result);
        this.coupanlist=result['coupon_code'];
        console.log(this.coupanlist);

        // if(this.coupanlist.length==0){
        //   this.data_not_found=true;
        // }else{
        //   this.data_not_found=false;
        // }

      }))
      // this.loader='';

    }

    get_production_data(search){
      console.log(search);

      this.loader = true;
      console.log("raw_material_list method calls");
      this.serve.fetchData(this.data,"Organization/product_list").subscribe((result=>{
        console.log(result);
        this.loader = false;
        this.product_list=result;
      })
      )

    }

    delete_coupan(coupan_id){
      console.log("delete coupan");
      console.log(coupan_id);

      this.alert.delete('Coupans !').then((result) => {
        if (result) {
          var data = {'id':coupan_id};
          this.serve.fetchData({'data':data}, "coupons/delete_coupon").subscribe((result) => {
            console.log(result);
            if (result['msg'] == "success") {
              console.log('in success function');
              this.alert.success("Coupon","Deleted Successfully");
              this.getcoupanlist();
            }
            else {
              console.log('in failed function');
              this.alert.error("Something Went Wrong Please Try Again !");
            }
          })
        }
      });

    }

    download_excel(id){
      var data = {'id':id};
      var time = Math.floor(Math.random()*90000) + 10000;
      this.serve.fetchData({'data':data}, "coupons/export_coupons?"+time).subscribe((result) => {
          console.log(result);
          console.log('in success function');
          this.alert.success("Coupon","Downloaded Successfully");
          console.log(this.serve.myurl2)
          window.open(this.serve.myurl2+'uploads/coupons.csv','_blank');
      },err=>{
        console.log(err);
      });
    }

    submitCoupan(){

      this.data.login_data = this.login_data['value']['data'];

      this.serve.fetchData({'data':this.data}, "coupons/generate_coupon").subscribe((result) => {
        console.log(result);
        if (result['msg'] == "success") {
          console.log('in success function');
          this.alert.success("Coupon","Generated Successfully");
          this.data={};
          this.getcoupanlist();

        }
        else {
          console.log('in failed function');
          this.alert.error("Something Went Wrong Please Try Again !");
        }
      },err=>{
        console.log(err);
      });
    }
    value(val){
      var product_name = product_name
      this.serve.fetchData({'data':val},"Organization/coupon_value_dropdown").subscribe((result=>{
        console.log(result);
        if (this.data.coupon_type=='Artisan') {
          
          this.data.coupon_value=result['karigar_coupon_value'];
        }
        else{
          this.data.coupon_value=result['retailer_coupon_value'];

        }
    }
  ))
    }
    blankdata(){
      this.data.coupon_qty='';
      this.data.product_name='';
      this.data.coupon_value='';
      this.data.remark='';

    }

}
