import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService';
import * as moment from 'moment';
import { MatDialog, MatSelect } from '@angular/material';
import { ChangeSchemeStatusComponent } from '../change-scheme-status/change-scheme-status.component';
import { DialogComponent } from '../dialog.component';


@Component({
  selector: 'app-scheme',
  templateUrl: './scheme.component.html',
  styleUrls: ['./scheme.component.scss']
})
export class SchemeComponent implements OnInit {
schemedata: any=[];
skelton:any=[];
search: any={};
loader:boolean=false;
data_not_found:boolean=false;

  constructor(public serve:DatabaseService , public dialogs: MatDialog,public dialog: DialogComponent) {

   }

  ngOnInit() {
    this.schemelist();
  }



  schemelist(){
    if(this.search.start_date)
    this.search.start_date=moment(this.search.start_date).format('YYYY-MM-DD');

    if(this.search.end_date)
    this.search.end_date=moment(this.search.end_date).format('YYYY-MM-DD');
    this.serve.fetchData(this.search,'Organization/scheme_list').subscribe((result=>{
      console.log(result);
      this.schemedata=result['scheme_data']
      console.log(this.schemedata);

    }));
  }
  openDialog(scheme_id): void {

    const dialogRef = this.dialogs.open(ChangeSchemeStatusComponent, {
      width: '400px', data:{

        id : scheme_id,
        reason: ''
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.schemelist();
    });
  }
  delete_scheme(id){
    console.log("delete_scheme method calls");



    this.dialog.delete('Scheme !').then((result) => {
      if (result) {
        this.serve.fetchData({'id':id},"Organization/delete_scheme").subscribe((result=>{
          console.log(result);
          this.schemelist();

        })
        )
      }
    });



  }
  refresh()
  {
    this.search={};
    this.schemelist();

  }
}
