import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
    selector: 'app-order-dispatch',
    templateUrl: './order-dispatch.component.html',
})
export class OrderDispatchComponent implements OnInit {
    loader:any=1;
    order_id:any=0;
    order_detail:any=[];
    order_item:any=[];
    logIN_user:any;
    uid:any;
    uname:any;
    urls:any=[]; 
    dispatch_data:any={};

    selectedFile:any=[];
     formData:any=new FormData();
  image_id:any={};
  warehouseid:any={};

    constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService,public toast : ToastrManager) {
        console.log(data);
        this.order_id = data['order_id'];
        this.warehouseid=data['warehouse']
        this.logIN_user = JSON.parse(localStorage.getItem('st_user'));
        console.log(this.logIN_user);
        this.uid = this.logIN_user['data']['id'];
        this.uname = this.logIN_user['data']['name'];

    }
    
    ngOnInit() {
        this.orderDetail();
    }
    
    orderDetail()
    {
        this.loader=1;
        let id={'order_id':this.order_id,'warehouse_id':this.warehouseid}
        this.serve.fetchData(id,"Order/order_detail").subscribe((result=>{
            console.log(result);
            this.order_detail=result['order_detail'];
            this.order_item=result['order_detail']['order_item'];
            this.order_detail.order_cgst = this.order_detail.order_gst/2;  
            this.order_detail.order_cgst = parseFloat(this.order_detail.order_cgst).toFixed(2);
            setTimeout (() => {
                this.loader='';
                
            }, 700);
        }))
    }
    
    
    
    update_order()
    {
        this.loader=1;

        console.log(this.order_item);
        this.serve.fetchData({'order_id':this.order_id,"data":this.order_item,'dr_id':this.order_detail.dr_id,'uid':this.uid,'uname':this.uname,'invoice_data':this.dispatch_data,'warehouse_id':this.warehouseid},"Order/dispatch_order")
        .subscribe(resp=>{
            console.log(resp);
            if(resp['dispatch_order']['msg'] == 'success')
            {
                this.loader = '';

                this.image_id = resp['dispatch_order']['image_id'];

                for(var i=0; i<this.selectedFile.length; i++)
                {
                  console.log('tessssssssssssss');
    
                  this.formData.append("image"+i,this.selectedFile[i],this.selectedFile[i].name);
                }
                this.formData.append('id',this.image_id);
                if(this.selectedFile && this.selectedFile.length > 0)
                {
                  console.log("image......");
    
                  this.loader = 1;
                  this.serve.upload_image(this.formData,"Order/Image_upload_product/"+this.image_id).subscribe((resp)=>
                  {
                    this.loader = '';
                    console.log(resp);
                    if(resp)
                    {
                        this.toast.successToastr("Image Uploaded Successfully...");
              
                    }
                    else
                    {
                      
                    }
                  });
                }
               this.toast.successToastr("Order Dispatched Successfully...");

                this.dialog.closeAll();
            }
            else{
                this.toast.errorToastr("Something Went Wrong...");

            }
        })
    }
    
    check_qty(indx)
    {
        console.log(this.order_item[indx]);

        if (parseInt(this.order_item[indx]['balance_stock']) < parseInt(this.order_item[indx]['dispatchQty']))
        {
            if(this.order_item[indx]['dispatchQty'] == null)
            {
                this.order_item[indx]['dispatchQty'] = 0;
                // this.toast.errorToastr("Currently stock not available, please enter valid quantity..");
            }
    
            // if(this.order_item[indx]['dispatchQty'] == 0)
            // {
            //     this.order_item[indx]['dispatchQty'] = this.order_item[indx]['pending_qty'];
            // }
            
            if(parseInt(this.order_item[indx]['dispatchQty']) > parseInt(this.order_item[indx]['pending_qty'] ))
            {
                this.order_item[indx]['dispatchQty'] = 0;
                this.toast.errorToastr("Dispatch Qty Should be less than pending Qty., please enter valid quantity..");
            }      
            if(parseInt(this.order_item[indx]['dispatchQty']) > parseInt(this.order_item[indx]['balance_stock']))
            {
                this.order_item[indx]['dispatchQty'] = 0;
                this.toast.errorToastr("Currently stock not available, please enter valid quantity..");
            }              
        }
        else
        {
            
            // this.order_item[indx]['dispatchQty'] = parseInt(this.order_item[indx]['balance_stock']);
        }
        
    }
    insertImage(event) {
        console.log(event);
    
        let files = event.target.files;
        console.log(files);
        if (files) {
          for (let file of files) {
            let reader = new FileReader();
    
            reader.onload = (e: any) => {
              console.log(e);
    
              this.urls.push(e.target.result);
              console.log(e);
    
            }
            reader.readAsDataURL(file);
          }
        }
        console.log(this.urls);
    
        for (var i = 0; i < event.target.files.length; i++) {
          this.selectedFile.push(event.target.files[i]);
        }
        console.log(this.selectedFile);
    
      }

      delete_img(index: any) {
        this.urls.splice(index, 1)
        this.selectedFile=[];
      }
}
