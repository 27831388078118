import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import {Router } from '@angular/router';

@Component({
  selector: 'app-karigar-add',
  templateUrl: './karigar-add.component.html',
  styleUrls: ['./karigar-add.component.scss']
})
export class KarigarAddComponent implements OnInit {
data:any={};
loader:boolean=false;
formData:any=new FormData();
selectedFile:any=[];
urls:any=[];
image_id:any
  constructor( public serve: DatabaseService,public toast : ToastrManager,public rout: Router,) { }

  ngOnInit() {
  }
  getaddressbypincode(pincode){
    this.loader=true
    console.log(pincode);
    
      this.serve.fetchData({'data':{'pincode':pincode}}, "Karigar/getAddressFromPincode").subscribe((result => {
        console.log(result); 
        if(result!=null){
          this.data.state=result['state_name']
          this.data.district=result['district_name']
          this.data.city=result['city']
          this.data.pincode=result['pincode']
          this.loader=false

        }  
        else{
          this.toast.errorToastr('Please Enter a vaild pincode..!');
          this.loader=false
          this.data.state_name='';
          this.data.district_name=''
          this.data.city=''
          this.data.pincode=''
        }
      }))
    
  }

  insertImage(event) {
    console.log(event);

    let files = event.target.files;
    console.log(files);
    if (files) {
      for (let file of files) {
        let reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e);

          this.urls.push(e.target.result);
          console.log(e);

        }
        reader.readAsDataURL(file);
      }
    }
    console.log(this.urls);

    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
    }
    console.log(this.selectedFile);

  }

  delete_img(index: any) {
    this.urls.splice(index, 1)
    this.selectedFile=[];
  }

  submitDetail()
  {
      this.loader=true;

      console.log(this.data);
      this.serve.fetchData({'data':this.data},"Karigar/submit_registration")
      .subscribe(resp=>{
          console.log(resp);
          if(resp['msg'] == 'success')
          {
              this.loader = false;

              this.image_id = resp['image_id'];

              for(var i=0; i<this.selectedFile.length; i++)
              {
                console.log('tessssssssssssss');
  
                this.formData.append("image"+i,this.selectedFile[i],this.selectedFile[i].name);
              }
              this.formData.append('id',this.image_id);
              if(this.selectedFile && this.selectedFile.length > 0)
              {
                console.log("image......");
  
                this.loader = true;
                this.serve.upload_image(this.formData,"Karigar/insert_image_artisan/"+this.image_id).subscribe((resp)=>
                {
                  this.loader = false;
                  console.log(resp);
                  if(resp)
                  {
                      this.toast.successToastr("Document Uploaded Successfully...");
            
                  }
                  else
                  {
                    
                  }
                });
              }
             this.toast.successToastr("Artisan Added Successfully...");
             this.rout.navigate(['/karigar-list/1']);

          }
          else{
              this.toast.errorToastr("Something Went Wrong Please contact to support!...");
              this.loader = false;

          }
      })
  }
}
