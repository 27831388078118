import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { RemarkComponent } from '../remark/remark.component';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {
  data_not_found:boolean=false;
  skelton:any;
  loader:any;
  item_data:any=[];
  data:any={};
  exp_loader:any;
  cancel:any={};

  constructor(public alert: DialogComponent,public serve:DatabaseService,  public dialogs: MatDialog ) { }

  ngOnInit() {
    this.purchse_item_list()
  }

  purchse_item_list()
  {

    if(this.data.purchaseOrder_date){
      this.data.purchaseOrder_date = moment(this.data.purchaseOrder_date).format("YYYY-MM-DD");
    }
    this.serve.fetchData({'data':this.data},"Organization/purchase_order_list").subscribe((result=>
      {
        console.log(result);
        this.item_data=result;
        console.log(this.item_data);
        if(this.item_data.length=='' ||this.item_data.length==null||this.item_data.length==undefined){
          this.data_not_found=true;
        }


      }))
  }

  edit_item()
  {

  }
  delete_item(id){
    this.alert.confirm('You will not be able to recover this  Data!').then((result) => {
      if (result) {
        this.serve.fetchData({ 'id': id, }, "Organization/delete_purchase_order").subscribe((result => {
          console.log(result);
          if (result == true) {
            this.purchse_item_list();

          }

        }))
      }
    });

  }

  cancel_item(id): void {

    const dialogRef = this.dialogs.open(RemarkComponent, {
      width: '400px', data:{
        id :id,
        remark: ''
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.purchse_item_list();
    });
  }



  exportAsXLSX(){

    this.exp_loader = true;
      this.serve.fetchData({'search':this.data},"Organization/purchase_order_list_excel")
      .subscribe(resp=>{
        console.log(resp);
        if(resp['msg']=='Success'){

        this.exp_loader = false;
        document.location.href = this.serve.myurl2+'uploads/Purchase_Orders.csv';

        }
      })

  }

}
