import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-scheme-detail',
  templateUrl: './scheme-detail.component.html',
  styleUrls: ['./scheme-detail.component.scss']
})
export class SchemeDetailComponent implements OnInit {
  scheme_id: any={};
  schemedata: any={};
  multiple: any=[];
  constructor(public serve:DatabaseService,public route:ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe( params => {
      console.log(params);
      this.scheme_id = params.id;
      console.log(this.scheme_id);
      if(this.scheme_id)
      {
        this. schemedetail()
        
      }
      
    });

  }
  schemedetail(){
    this.serve.fetchData({'id':this.scheme_id},"Organization/scheme_detail").subscribe((result=>
      {
        console.log(result);
        this.schemedata=result['scheme_data'];
        console.log(this.schemedata);
        this.multiple=result['data']
      }))
  }
}
