import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { data } from 'jquery';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-remark',
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.scss']
})
export class RemarkComponent implements OnInit {
  // remarkdata:any;

  constructor( @Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService) {}

  ngOnInit() {
  }
  remarkcancel()
  {
    
    this.serve.fetchData({'remark':this.data.remark,'id':this.data.id},"Organization/cancel_purchase_order").subscribe((result=>{
      console.log(result);
    }))
    this.dialog.closeAll();
    
  }
}
