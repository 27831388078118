import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-edit-order-item',
  templateUrl: './edit-order-item.component.html',
  styleUrls: ['./edit-order-item.component.scss']
})
export class EditOrderItemComponent implements OnInit {

  form:any={};
  productlist:any=[];
  product_list:any={};
  addList:any=[];
  loader:boolean=false;
  add_list:any=[];
  total_qty:any=0;
  netamount:any=0;
  total_gst_amount: any = 0;
  new_grand_total: any = 0;
  logindata:any={};
  loginUser:any;
  insurance:any;
  tmp_total:any;
  order_id:any;
  userDetail:any;
  addToListButton:boolean = false;
  saveOrderbtn:boolean=false

  constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService,public toast : ToastrManager) 
  { 
    console.log(this.data);
    this.get_order_detail();
    this.item_list()
    this.data.trader_disc=0
  }
  
  ngOnInit() {
  }
  
  get_order_detail()
  {
    this.loader=true
    this.serve.fetchData({'order_id':this.data.order_id},"Order/order_detail").subscribe(result=>
      {
        console.log(result);
        this.loader=false
      this.add_list=result['order_detail']['order_item']
      this.userDetail=result['order_detail']
      this.total_qty=this.userDetail.qty;
      this.total_gst_amount=this.userDetail.order_gst;
      this.netamount=this.userDetail.sub_total;
      this.insurance=this.userDetail.insurance;
      this.data.qty_discount=this.userDetail.qty_discount;
      this.data.cash_discount=this.userDetail.cash_discount;
      this.new_grand_total=this.userDetail.order_grand_total;
      this.tmp_total=this.netamount;
      });
    }


    item_list()
  {
    this.loader=true
    this.serve.fetchData({},"Order/itemListWeb").subscribe(result=>
      {
        console.log(result);
        this.productlist=result['Item_list']

       this.loader=false
      
      });
    }
    selectedproductdetail(row){
      console.log(row);
      this.product_list=row
      console.log(this.product_list);
      
      
    }
    
    addToList()
{

    // console.log(this.productIemArray);
   console.log(this.product_list.gst);
console.log(this.add_list);

    // this.data.rate = this.data.qty * this.product_list.mrp;
    this.data.rate = ((this.data.qty * this.product_list.base_price)+(this.data.qty * this.product_list.base_price)*this.product_list.gst/100)-((this.data.qty * this.product_list.base_price)+(this.data.qty * this.product_list.base_price)*this.product_list.gst/100)*this.data.trader_disc/100
    
    console.log(this.data.rate);

    // this.product_list['qty'] = this.data.qty;
    this.product_list['qty'] = this.data.qty;
    this.product_list['trader_discount'] = this.data.trader_disc;

    // this.product_list['rate'] = this.product_list.mrp;
    this.product_list['rate'] = this.product_list.base_price;
    this.product_list['gst'] = this.product_list.gst;

    this.product_list['amount'] = this.data.rate;
    this.product_list['brand'] = this.product_list['brand'];
    this.product_list['category'] = this.product_list['category'];
    this.product_list['product_name'] = this.product_list['product_name'];
    this.product_list['size'] = this.product_list['size'];
    this.product_list['item_code'] = this.product_list['item_code'];
    this.product_list['prod_id'] = this.product_list['id'];
    this.product_list['product_id'] = this.product_list['id'];
    this.product_list['state'] = this.product_list['state'];

    // console.log();


    let existIndex = this.add_list.findIndex(row=>row.product_id == this.product_list['prod_id']);
    console.log(existIndex);

      if(existIndex == -1)
      {
        console.log('in if');
        
        this.add_list.push(this.product_list);
      }
      else{
        console.log( this.add_list[existIndex]['qty']);
        console.log(this.product_list['qty']);
        
        this.add_list[existIndex]['qty'] = parseFloat(this.data.qty) + parseFloat(this.add_list[existIndex]['qty']);
        this.add_list[existIndex]['trader_discount'] = parseFloat(this.product_list['trader_discount']) + parseFloat(this.add_list[existIndex]['trader_discount']);
        this.add_list[existIndex]['gst_amount']=parseFloat(this.add_list[existIndex]['qty']) * parseFloat(this.product_list['rate'])*this.add_list[existIndex]['gst']/100;
        this.add_list[existIndex]['trader_discount_amount']=(parseFloat(this.add_list[existIndex]['qty'])* parseFloat(this.product_list['rate'])+this.add_list[existIndex]['gst_amount'])*this.add_list[existIndex]['trader_discount']/100;
        console.log(this.add_list[existIndex]['trader_discount_amount']);
        console.log(this.add_list[existIndex]['gst_amount']);

        
        this.add_list[existIndex]['amount'] = (parseFloat(this.add_list[existIndex]['qty']) * parseFloat(this.product_list['rate']))+this.add_list[existIndex]['gst_amount']-this.add_list[existIndex]['trader_discount_amount']
        // this.add_list[existIndex]['amount'] = parseFloat(this.add_list[existIndex]['qty']) * parseFloat(this.product_list['rate'])+((parseFloat(this.add_list[existIndex]['qty']) * parseFloat(this.product_list['rate']))*this.add_list[existIndex]['gst']/100)-(parseFloat(this.add_list[existIndex]['qty']) * parseFloat(this.product_list['rate']))+((parseFloat(this.add_list[existIndex]['qty']) * parseFloat(this.product_list['rate']))*this.add_list[existIndex]['gst']/100*this.add_list[existIndex]['trader_discount']/100)
      }
    console.log(this.product_list);
    console.log(this.add_list);


   

    this.total_qty = 0;
    this.netamount = 0;
    this.total_gst_amount = 0;
    this.insurance=0;
    for(let i = 0; i < this.add_list.length; i++){
        this.total_qty = (parseInt(this.total_qty) + parseInt(this.add_list[i]['qty']));
        this.netamount = parseFloat(this.netamount) +  (parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate'])+(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate']))*parseFloat(this.add_list[i]['gst'])/100-(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate'])+(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate']))*parseFloat(this.add_list[i]['gst'])/100)*parseFloat(this.add_list[i]['trader_discount'])/100);

        this.total_gst_amount = (parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate']))*parseFloat(this.add_list[i]['gst'])/100 + parseFloat(this.total_gst_amount);
    }

    this.netamount = parseFloat(this.netamount);
    
    this.total_gst_amount = parseFloat(this.total_gst_amount);
    this.netamount = this.netamount.toFixed(2);
    this.total_gst_amount = this.total_gst_amount.toFixed(2);
    this.insurance=this.netamount*0.5/100
    this.insurance=this.insurance.toFixed(2);
    console.log(this.insurance);
    console.log(typeof this.insurance);
    console.log(typeof this.netamount);

    this.new_grand_total=parseFloat(this.netamount)+parseFloat(this.insurance);
    console.log(this.new_grand_total);
    
    this.new_grand_total = parseFloat(this.new_grand_total)
    this.new_grand_total = this.new_grand_total.toFixed(2)
    this.tmp_total= this.new_grand_total

    if(this.data.qty_discount){
        this.addqtydiscounts()
    }
    // console.log(this.new_grand_total);

    // this.product_list= {}
    this.data.productid = '';
    this.data.qty = 0;
    this.data.trader_disc = '';


}
tmp_new_total:any;
addqtydiscounts(){
    console.log(this.data.qty_discount);
    // console.log(this.data.cash_discount);
    this.tmp_new_total=(this.tmp_total)-(this.tmp_total*this.data.qty_discount/100)
    this.new_grand_total=parseFloat(this.tmp_new_total)
    this.new_grand_total = this.new_grand_total.toFixed(2);
    console.log(this.new_grand_total);
    if (this.data.cash_discount) {
        this.addcashdiscounts();
    }
    
}
addcashdiscounts(){
    // console.log(this.data.qty_discount);
    console.log(this.data.cash_discount); 
    this.new_grand_total=this.tmp_new_total-(this.tmp_new_total*this.data.cash_discount/100)
    this.new_grand_total=parseFloat(this.new_grand_total)
    this.new_grand_total = this.new_grand_total.toFixed(2);
    console.log(this.new_grand_total);
    
}

listdelete(i)
{
    this.add_list.splice(i, 1);
    console.log(this.add_list);

    this.total_qty = 0;
    this.netamount = 0;
    this.total_gst_amount = 0;
    this.new_grand_total = 0;
    this.insurance =0;
    // this.data.qty_discount=0;
    // this.data.cash_discount=0;

    for (let i = 0; i < this.add_list.length; i++) {
        this.total_qty = parseFloat(this.total_qty) + parseFloat(this.add_list[i]['qty']);
        this.netamount = parseFloat(this.netamount) +  (parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate'])+(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate']))*parseFloat(this.add_list[i]['gst'])/100-(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate'])+(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate']))*parseFloat(this.add_list[i]['gst'])/100)*parseFloat(this.add_list[i]['trader_discount'])/100);

        this.total_gst_amount = this.total_gst_amount+(parseFloat(this.add_list[i]['qty']) * parseFloat(this.add_list[i]['rate']))*parseFloat(this.add_list[i]['gst'])/100 
        this.new_grand_total = parseFloat(this.new_grand_total) + parseFloat(this.add_list[i]['subtotal'])
    }
    this.netamount = this.netamount.toFixed(2);
    // this.total_gst_amount = parseFloat(this.total_gst_amount);
    // this.netamount = this.netamount.toFixed();
    this.total_gst_amount = this.total_gst_amount.toFixed(2);
    this.insurance=parseFloat(this.netamount)*0.5/100;
    this.insurance=this.insurance.toFixed(2);
    console.log(this.insurance);
    console.log( typeof this.insurance);
    console.log( typeof this.netamount);

    this.new_grand_total=parseFloat(this.netamount)+parseFloat(this.insurance)
    this.new_grand_total = this.new_grand_total.toFixed(2)
    console.log(this.new_grand_total);
    this.tmp_total= this.new_grand_total
    this.tmp_new_total=(this.tmp_total)-(this.tmp_total*this.data.qty_discount/100)
    // this.addcashdiscounts();
     this.addqtydiscounts()
    // this.new_grand_total = parseFloat(this.new_grand_total)
    // this.netamount = this.netamount.toFixed();
    // this.total_gst_amount = this.total_gst_amount.toFixed();


}
check_traderdesc(type){
  console.log(type);
  if (type=='trader_discount') {
      if(this.data.trader_disc>23){
          // tis.alertCtrl.create('Trader Discount Must be less than 23%')
          
        this.toast.errorToastr("Trader Discount Must be less than 23%..");
             
          this.addToListButton=true
          this.data.trader_disc=0;

          return
      

      }
      else{
          console.log('in else');
          
          this.addToListButton=false;

      }
  }
  if (type=='Quantity_desc') {
      if(this.data.qty_discount>6){
          // this.alertCtrl.create('Trader Discount Must be less than 23%')
        this.toast.errorToastr("QTY Discount Must be less than 6%..");
          
                  
          this.saveOrderbtn=true
          this.data.qty_discount=0;
          
          return
      }

      
      else{
          console.log('in else');
          this.saveOrderbtn=false

         

      }
  }
  if (type=='cash_desc') {
      if(this.data.cash_discount>5){
          // this.alertCtrl.create('Trader Discount Must be less than 23%')
        this.toast.errorToastr("Cash Discount Must be less than 5%..");
          
             
          this.saveOrderbtn=true
          this.data.cash_discount=0;
          return
      }

      }
      else{
          console.log('in else');
          

          this.saveOrderbtn=false;

      }
    }
  

save_order(){
  this.loader=true

  console.log(this.data);

  var orderData = {'sub_total':this.netamount,'grand_total':this.new_grand_total,'total_gst_amount':this.total_gst_amount,'total_qty':this.total_qty,'net_total':this.netamount,'insurance':this.insurance,'cash_discount':this.data.cash_discount,'qty_discount':this.data.qty_discount,'order_id':this.data.order_id, 'dr_id':this.userDetail.dr_id}
  console.log(orderData);
  console.log(this.add_list);

  this.serve.fetchData({"cart_data":this.add_list,'orderData':orderData,},"order/order_update").subscribe(resp=>{
      console.log(resp);
      if(resp['msg']=='success'){
        this.toast.successToastr("Order Updated Successfully...");
        this.dialog.closeAll();
        this.loader=false

      }
      else{
        this.toast.errorToastr("Something Went Wrong!..");
        this.loader=false

      }
    
  })
}
}
