import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';

@Component({
  selector: 'app-coupan-list',
  templateUrl: './coupan-list.component.html',
  styleUrls: ['./coupan-list.component.scss']
})
export class CoupanListComponent implements OnInit {
  coupanCodeList:any;
  search:any={};
  active_tab:any='All';
  main_tab:any='Artisan';

  skelton:any={};
  data_not_found=false;
  excel_data:any=[];
  total_page:any;
  pagenumber:any=1;
  pagelimit: any = 50;
  coupanCodeListexcel:any=[];
  deleted_count:any;
  loader:boolean=false;
  is_multiple_delete=false;
  is_checked=false;
  userId:any;
   userName:any;
   userData:any;

  constructor(public serve:DatabaseService,public alert: DialogComponent,public navparams: ActivatedRoute,public dialog:MatDialog,public dialogs: DialogComponent,public toast : ToastrManager) {
    this.skelton = new Array(10);
    this.userData = JSON.parse(localStorage.getItem('st_user'));
    console.log(this.userData);
    this.userId=this.userData['data']['id'];
    console.log(this.userName);
    this.userName=this.userData['data']['name'];
    console.log(this.userId);
   }

  ngOnInit() {
    this.getcoupanCodeList();
  }

  refresh(){
    this.search={};
    this.getcoupanCodeList();

  }

  availcount:any;
  allcount:any;
  scaned:any;
  getcoupanCodeList(){
    this.coupanCodeList=[]
    console.log(this.search);
    if(this.search.date_from)
    {
      this.search.date_from=moment(this.search.date_from).format('YYYY-MM-DD');
      console.log(this.search.date_from)
    }
    if(this.search.date_to)
    {
      this.search.date_to=moment(this.search.date_to).format('YYYY-MM-DD');
      console.log(this.search.date_to);
    }
    if(this.search.created_date)
    {
      this.search.created_date=moment(this.search.created_date).format('YYYY-MM-DD');
      console.log(this.search.created_date)
    }
    this.loader=true;
    this.serve.fetchData({'search':this.search,'active_tab':this.active_tab,'page':this.pagenumber,'coupon_type':this.main_tab},"coupons/coupon_code_listing").subscribe((result=>{
      console.log(result);
      this.loader=false;
      this.coupanCodeList=result['coupon_code'];
      this.allcount=result['all_count'];
      this.availcount=result['avail_count'];
      this.scaned=result['scan_by_karigar_count'];
      this.deleted_count=result['deleted_count'];
      console.log(this.coupanCodeList);
      if(this.active_tab == 'Scaned'){
        this.total_page  = Math.ceil(parseInt(this.scaned)/50);
      }else if(this.active_tab == 'Available'){
        this.total_page  = Math.ceil(parseInt(this.availcount)/50);
      }else if(this.active_tab == 'All'){
        this.total_page  = Math.ceil(parseInt(this.allcount)/50);
      }

      console.log('COUPAN CODE LIST');

      // if(this.coupanCodeList.length == 0)
      // {
      //   this.data_not_found=true;
      // }
      // else
      // {
      //   this.data_not_found=false;
      // }
    }))

  }

  exportAsXLSX(){
    console.log(this.coupanCodeList);
    console.log(this.search);
    if(this.search.date_from)
    {
      this.search.date_from=moment(this.search.date_from).format('YYYY-MM-DD');
      console.log(this.search.date_from)
    }
    if(this.search.date_to)
    {
      this.search.date_to=moment(this.search.date_to).format('YYYY-MM-DD');
      console.log(this.search.date_to);
    }
    if(this.search.created_date)
    {
      this.search.created_date=moment(this.search.created_date).format('YYYY-MM-DD');
      console.log(this.search.created_date)
    }
    this.loader=true;
    this.serve.fetchData({'search':this.search,'active_tab':this.active_tab,},"coupons/coupon_code_listing").subscribe((result=>{
      console.log(result);
    this.loader=false;
      this.coupanCodeListexcel=result['coupon_code'];
     for(let i=0;i<this.coupanCodeListexcel.length;i++){
      this.excel_data.push({'Date Created':this.coupanCodeListexcel[i].date_created,'Expiry Date':this.coupanCodeListexcel[i].coupon_expiry_date,'Coupon Code':this.coupanCodeListexcel[i].coupon_code,'Product Detail':this.coupanCodeListexcel[i].product_name,'Coupon Type':this.coupanCodeListexcel[i].coupon,'Scanned by':this.coupanCodeListexcel[i].name?this.coupanCodeListexcel[i].name:'','Phone':this.coupanCodeListexcel[i].mobile?this.coupanCodeListexcel[i].mobile:''});
    }
    this.serve.exportAsExcelFile(this.excel_data,'Coupon Sheet');
    // this.excel_data = [];
  }));
  }
  
  alert_for_length(){
    // this.dialogs.error("Please Enter atleast 5 character for search..")
    this.toast.warningToastr("Please Enter atleast 5 character for search...");
  }

multiple_delete(){
    this.is_multiple_delete=true;
    console.log(this.is_multiple_delete);
}
delete_data:any=[]
OndeleteRowselected(event,app_row){
console.log(app_row);
console.log(event);
console.log(event.target.checked);
if(event.target.checked)
{
 this.is_checked=true
    console.log('in if');
    this.delete_data.push(app_row);
    console.log(this.delete_data);
    
}

else
{
    let exist =this.delete_data.findIndex(row=>row.id==app_row.id);
    console.log(exist);
    if (exist != -1) 
    {
        this.delete_data.splice(exist,1)
    }
    
    console.log('in else unchecked');
}
console.log(this.delete_data);
if(this.delete_data.length==0){
    this.is_checked=false

}
}
Cancel_deletion(){
  this.is_multiple_delete=false;
  this.is_checked=false;
  console.log(this.is_multiple_delete);
  this.delete_data=[];
}
delete_selected_row(){
  console.log(this.delete_data);
  this.dialogs.delete('Selected Coupon Data').then((result) => {
    console.log(result);
    if (result) {
      this.loader=true;
      this.serve.fetchData({'data':this.delete_data,'login_id':this.userId,'login_name':this.userName},"coupons/DEACTIVE_COUPON")
      .subscribe(resp=>{
        console.log(resp);
        if(resp['status']=='Success'){
          this.dialogs.success('Coupons','Deleted Successfully..')
         this.loader=false;
          this.is_multiple_delete=false;
          this.is_checked=false
          this.delete_data=[]
          this.getcoupanCodeList();
  
        }
        else{
          this.toast.errorToastr("Something Went Wrong!...");
          this.loader=false;
  
        this.is_multiple_delete=false;
        this.is_checked=false
        this.delete_data=[]
        }
      });
    }
    else{
      this.is_multiple_delete=false;
      this.is_checked=false
      this.delete_data=[]
    }
    
  })
  }
  backtodefault(){
    this.is_multiple_delete=false;
    this.is_checked=false;
    console.log(this.is_multiple_delete);
    this.delete_data=[];
  }


  ReopenserailNo(couponcode,type){
    this.dialogs.confirm('Reopen coupon ' + couponcode).then((result) => {
      console.log(result);
      if (result) {
        this.loader=true;
        this.serve.fetchData({'data':{'coupon':couponcode,'createdBy':this.userId,'type':type}},"coupons/reOpenCoupon")
        .subscribe(resp=>{
          console.log(resp);
          if(resp['statusCode']=='200'){
            this.dialogs.success('Coupons','Reopened Succussfully!')
            this.loader=false;
            this.getcoupanCodeList();
    
          }
          else{
            this.toast.errorToastr(resp['statusMessage']);
            this.loader=false;
          }
        },err=>{
          this.toast.errorToastr('Something Went Wrong....');
        });
      }
      else{
      console.log('no clicked');
      
      }
      
    })
    }
}
