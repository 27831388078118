import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { DatabaseService } from 'src/_services/DatabaseService';

@Component({
  selector: 'app-network-report',
  templateUrl: './network-report.component.html',
  styleUrls: ['./network-report.component.scss']
})
export class NetworkReportComponent implements OnInit {

  data:any={};
  reportList:any;
  search:any={};
  reportHeading:any;
  reportingdata:any;
  constructor(public serve:DatabaseService) { }

  ngOnInit() {
    this.getReportList();
  }
  refresh(){

  }

  getReportList(action:any='')
  {
    console.log(action);

    if(action == "refresh")
    {
      this.search = {};
      this.reportList =[];
      this.reportingdata = '';
      return
    }
    console.log(this.data);

    if(action == "search")
    {
      this.reportingdata.search = this.search;
    }


    this.serve.fetchData({'data':this.reportingdata},"Organization/getRmReport").subscribe((result=>
      {
        console.log(result);

        this.reportList = result['data']['executive_orders'];
        console.log(this.reportList);
      }))
    }

  submitDetail(f:NgForm){

    console.log(this.data);

    if(this.data.date_from)
    {
      this.data.date_from=moment(this.data.date_from).format('YYYY-MM-DD');
      console.log(this.data.date_from)
    }

    if(this.data.date_to)
    {
      this.data.date_to=moment(this.data.date_to).format('YYYY-MM-DD');
      console.log(this.data.date_to);

    }

    if(this.data.user_name && !this.data.user_id){
      this.data.user_id = this.userlist.filter(val => val.user_name == this.data.user_name)[0];
      console.log('user_id from ts page');
    }

    if(this.data.company_name && !this.data.user_id){
      this.data.user_id = this.userlist.filter(val => val.company_name == this.data.user_name)[0];
      console.log('user_id from ts page');
    }

    if(this.data.role_name && this.data.user_name){
      this.reportHeading =this.data.user_name +" - " + this.data.role_name;
    }else{
      this.reportHeading = this.data.company_name + " - " +this.data.dr_code;
    }

    this.reportingdata = JSON.parse(JSON.stringify(this.data));

    this.serve.fetchData({'data':this.data},"Organization/getRmReport").subscribe((result=>
      {
        console.log(result);

        this.reportList = result['data']['executive_orders'];
        console.log(this.reportList);

      }))

    f.resetForm();
    this.data = {};

  }

  userlist:any;
  getUserList(val,type) {
    if(type=''){
      val = '';
    }
    this.serve.fetchData({search : val}, "Attendance/user_list").subscribe((result) => {
      console.log(result);

      this.userlist = result['user_list'];
      console.log(this.userlist);

    })

  }

  distributor_list:any;
  dr_count:any;
  distributorList(action:any='')
  {
    let name:any;

    if(this.data.company_name ){
      name = {'company_name':this.data.company_name};
    }

    this.serve.fetchData({'search':name,'type':this.data.type},"Distributors/distributor")
    .subscribe((result=>{
      console.log(result);
      this.dr_count = result['distributor']['count'];
      this.distributor_list=result['distributor']['distributor'];
      console.log(this.distributor_list);
    }))


    console.log(this.search);


  }

  excel_data: any=[];
  exportAsXLSX():void {

    for(let i=0;i<this.reportList.length;i++){
      this.excel_data.push({'Created By':(this.reportList[i].created_by_type == 'Executive')?this.reportList[i].creator_name:this.reportList[i].dr_name,'Creator Type':(this.reportList[i].created_by_type == 'Executive')?this.reportList[i].designation:this.reportList[i].created_by_type,'Order For':this.reportList[i].company_name,'Customer Type':this.reportList[i].customer_type,'Order No':this.reportList[i].order_no,'Order Qty':this.reportList[i].qty,'Order Value':this.reportList[i].sub_total,'Status':this.reportList[i].order_status,'Order Type':this.reportList[i].type,'State':this.reportList[i].state,'Assigned to':(this.reportList[i].created_by_type == 'Executive')?this.reportList[i].reporting_manager:this.reportList[i].dr_reporting_manager});
    }
    this.serve.exportAsExcelFile(this.excel_data,'Network Report');
    this.excel_data = [];

  }

}
