import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService';
import { sessionStorage } from 'src/app/localstorage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';


@Component({
  selector: 'app-add-purchase-order',
  templateUrl: './add-purchase-order.component.html',
  styleUrls: ['./add-purchase-order.component.scss']
})
export class AddPurchaseOrderComponent implements OnInit {
  order_detail:any={};
  item_detail:any={};
  charge:any={};
  address_t:any=[];
  vendor_data:any=[];
  organizaton_data:any=[];
  state_list:any=[];
  masurement_list:any=[];
  selected_state:any=[];
  multiple_state:any=[];
  item_list:any=[];
  amount:any=[];
  item_array:any=[];
  total:number=0;
  login_data:any=[];
  total_qty=0;
  total_amount=0;
  item_data:any={};
  id:any = 0
  tmp_product_List : any =[]
  pendingOrder: any = {};
  subtotal:any = 0;
  grandTotal:any = 0;
  // termsNcondition='<div>1). Please mention place of supply as "Gujarat" and State Code - 24 in the invoice to ensure that our eligibility of claiming credit of GST amount is not adversely impacted.</div><div>2). If the supply is from within Gujarat kindly charge CGST&SGST at the applicable rates and if the supply is from outside Gujarat, kindly charge IGST at the applicable rate to ensure that our eligibility of claiming credit of the said levies is not adversely impacted.</div><div>3). Weighment at our site and analysis in our laboratory will be considered final for the payment.</div><div>4). Delivery Challan and Invoices in duplicate should be forwarded along with supply.</div><div>5). Rates mentioned in the order are firm and not subject to any price variation or escalation.</div><div>6). All disputes involving litigation shall be Ahmedabad Jurisdiction only.</div><div>If any of the following details are missing in your Tax Invoice, the material will not be Inwarded/Payment</div><div>1). Required Original / Duplicate Tax Invoice duly authorised and Signed containing.</div><div>2). Mention our Name & Full address of our works in your Invoice copy.</div><div>3). Mention Tax Invoice and Original for Recipient in your Invoice copy.</div><div>4). Mention HSN Code of all supply items in your Invoice copy.</div><div>5). Mention your Name and Full Address with GST Registration Number in your Invoice copy.</div><div>6). Signature required even in Proforma Invoice also for Advance payment.</div>';
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],

    ]
  };

  constructor(public serve: DatabaseService, public session: sessionStorage, private router: Router, public route: ActivatedRoute, public alert: ToastrManager) {

    this.login_data = this.session.getSession();
    this.login_data = this.login_data.value;
    this.login_data = this.login_data.data;
    console.log(this.login_data);
    this.pendingOrder.total_pending_qty = 0;

    this.route.params.subscribe( params => {
      console.log(params);
      this.id = params.id;
      console.log(this.id);
    });
    if(this.id != 0){
      this.get_purchase_detail_for_update();

    }

    this.order_detail.termsNcondition='<div>1). Please mention place of supply as "Gujarat" and State Code - 24 in the invoice to ensure that our eligibility of claiming credit of GST amount is not adversely impacted.</div><div>2). If the supply is from within Gujarat kindly charge CGST&SGST at the applicable rates and if the supply is from outside Gujarat, kindly charge IGST at the applicable rate to ensure that our eligibility of claiming credit of the said levies is not adversely impacted.</div><div>3). Weighment at our site and analysis in our laboratory will be considered final for the payment.</div><div>4). Delivery Challan and Invoices in duplicate should be forwarded along with supply.</div><div>5). Rates mentioned in the order are firm and not subject to any price variation or escalation.</div><div>6). All disputes involving litigation shall be Ahmedabad Jurisdiction only.</div><div>If any of the following details are missing in your Tax Invoice, the material will not be Inwarded/Payment</div><div>1). Required Original / Duplicate Tax Invoice duly authorised and Signed containing.</div><div>2). Mention our Name & Full address of our works in your Invoice copy.</div><div>3). Mention Tax Invoice and Original for Recipient in your Invoice copy.</div><div>4). Mention HSN Code of all supply items in your Invoice copy.</div><div>5). Mention your Name and Full Address with GST Registration Number in your Invoice copy.</div><div>6). Signature required even in Proforma Invoice also for Advance payment.</div>';

  }

  ngOnInit() {
    this.vendor_list();
    this.organization_list();
    this.measurement_of_item();
    this.charge.packing = 0;
    this.charge.transport = 0;
    this.charge.other = 0;
    this.charge.cgst_amt = 0;
    this.charge.sgst_amt = 0;
    this.charge.igst_amt = 0;
    this.charge.gst =0;
  }

  vendor_list()
  {
    this.serve.fetchData({},"Organization/vendor_list").subscribe((result=>
      {
        console.log(result);
        this.vendor_data=result;
        console.log(this.vendor_data);
      }))
    }

    organization_list(){
      this.serve.fetchData({},"Organization/organization_list").subscribe((result=>
        {
          console.log(result);
          this.organizaton_data=result;
        }))
      }
      address(id){
        this.serve.fetchData({'id':id},"Organization/address_type_drop").subscribe((result=>
          {
            console.log(result);
            this.address_t=result;
            console.log(this.address_t);

          }));
        }

        assign_state()
        {
          console.log(this.multiple_state);
          this.selected_state=this.multiple_state['_pendingValue']
          console.log(this.selected_state);
        }

        state(id)
        {
          console.log(id);

          this.serve.fetchData({'id':id},"Organization/assignstate").subscribe((result=>
            {
              console.log(result);
              this.state_list=result;
              console.log(this.state_list);

            }))
          }

          vendor_dealing_item(id)
          {
            this.serve.fetchData({'id':id},"Organization/deal_item").subscribe((result=>
              {
                console.log(result);
                this.item_list=result;
                this.tmp_product_List = this.item_list;
                console.log(this.item_list);

              }))
            }
            measurement_of_item()
            {
              this.serve.fetchData({},"Organization/measurement_list").subscribe((result=>
                {
                  console.log(result);
                  this.masurement_list=result;
                  console.log(this.masurement_list);

                }))
              }

              convert_int(val){
                console.log(val);

                return parseFloat(val);
                this.total=val;
              }
              temp_id:any;
              temp(tmp_item_data){

                console.log(tmp_item_data);
                this.item_data=tmp_item_data
                console.log(this.item_data);
                this.item_detail.uom=tmp_item_data.unit_of_measurment
                console.log(this.item_detail.uom);
                this.item_detail.hsn_code=tmp_item_data.hsn_code
                console.log(this.item_detail.hsn_code);
                this.temp_id=tmp_item_data.raw_id
                this.check_last_price(this.temp_id)

              }
              show_last_price:boolean=false;
              last_purchase_price:any;
              check_last_price(id){
              console.log(id);
              this.serve.fetchData({id:id}, "Organization/getLastPurchaseItemPrice").subscribe((result => {
                console.log(result);
                if(result['data']!=null){

                  this.last_purchase_price=result['data']['price'];
                  this.show_last_price=true;
                }
              }))
              }

              get_po_pending_item(id)
              {
                this.pendingOrder = {};

                this.serve.fetchData({id:id}, "Organization/GET_PENDING_PO_ITEM").subscribe((result => {
                  console.log(result);
                  this.pendingOrder = result['data'];
                }))
              }

              calculate_amount()
              {
                console.log(this.item_array);
                this.pendingOrder.total_pending_qty = 0;
                this.item_detail.amount =  this.item_detail.amount.toFixed(2);

                let index= this.item_array.findIndex(row=>row.raw_id==this.item_data.raw_id && row.state==this.item_detail.state_name && row.hsn_code==this.item_detail.hsn_code && row.price==this.item_detail.price  && row.discount==this.item_detail.discount)
                console.log(index);
                if(index!=-1)
                {
                  this.item_array[index].qty=parseFloat(this.item_array[index].qty)+parseFloat(this.item_detail.qty);
                  console.log(this.item_array[index].qty);
                  this.item_array[index].amount=parseFloat(this.item_array[index].amount)+(this.item_detail.qty*this.item_detail.price);
                  console.log(this.item_array[index].amount);

                }
                else
                {
                  this.item_array.push({
                    raw_id:this.item_data.raw_id,
                    item_code:this.item_data.item_code,
                    item_name:this.item_data.item_name,
                    price:this.item_detail.price,
                    qty:this.item_detail.qty,
                    uom: this.item_detail.uom,
                    discount:this.item_detail.discount,
                    amount:this.item_detail.amount,
                    state:this.item_detail.state_name,
                    hsn_code: this.item_detail.hsn_code,
                    lead_time:this.item_data.lead_time,
                    brand:this.item_data.brand,
                    unit_of_measurement:this.item_data.unit_of_measurment,
                    // hsn_code: this.item_data.hsn_code,
                    ind_no: this.item_data.ind_no,
                  })
                  console.log(this.item_array);

                }

                this.total_qty=0;
                this.total_amount=0;

                for(let i=0;i<this.item_array.length;i++)
                {
                  this.total_qty= parseInt(this.item_array[i].qty) + (this.total_qty);
                  this.total_amount= parseFloat(this.item_array[i].amount) + (this.total_amount);
                  this.item_detail={};
                  this.subtotal = this.total_amount + (this.charge.packing?this.charge.packing:0) + (this.charge.transport?this.charge.transport:0) +  (this.charge.other?this.charge.other:0);
                }
                console.log(this.subtotal);
                this.calc_amt('','');

                console.log(this.item_array);
              }

              calc_amt(input_amt,input_type){

                console.log(input_type);
                console.log(input_amt);
                console.log(this.total_amount);
                console.log(this.charge.packing);
                console.log(this.charge.transport);
                console.log(this.charge.other);
                console.log(this.charge.gst);

                if(input_type == "Packaging" || input_type == "Transportation" || input_type == "Other"){

                  this.subtotal = this.total_amount + (this.charge.packing?this.charge.packing:0) + (this.charge.transport?this.charge.transport:0);

                }

                let gst_amt = 0;
                this.grandTotal = 0;
                gst_amt = (((this.charge.gst?this.charge.gst:0)/100)*this.subtotal);
                if(this.charge.gstType == 'cgst_gst'){
                  this.charge.cgst_amt = this.charge.sgst_amt = gst_amt;
                  this.charge.igst_amt = 0;
                  this.grandTotal = this.subtotal + (this.charge.cgst_amt*2) + (this.charge.other?this.charge.other:0);
                  this.charge.grandTotal = parseFloat(this.grandTotal) ;
                  this.charge.subtotal = this.subtotal;
                }else{
                  this.charge.igst_amt = gst_amt;
                  this.charge.cgst_amt = this.charge.sgst_amt = 0;
                  this.grandTotal = this.subtotal + this.charge.igst_amt + (this.charge.other?this.charge.other:0);
                  this.charge.grandTotal = parseFloat(this.grandTotal) + (this.charge.other?parseFloat(this.charge.other):0);
                  this.charge.subtotal = this.subtotal;
                }
              }

              delete_item(index)
              {
                this.item_array.splice(index,1)
                this.total_qty=0;
                this.total_amount=0;

                for(let i=0;i<this.item_array.length;i++)
                {
                  this.total_qty= parseInt(this.item_array[i].qty)+(this.total_qty);
                  this.total_amount=parseFloat(this.item_array[i].amount)+(this.total_amount);
                  this.subtotal = this.total_amount + (this.charge.packing?this.charge.packing:0) + (this.charge.transport?this.charge.transport:0) +  (this.charge.other?this.charge.other:0);

                  this.serve.fetchData({'id': this.item_array[i].id},"Organization/delete_purchase_order_item").subscribe((result=>
                    {
                      console.log(result);

                    }))
                  }
                  this.calc_amt('','');

                }



                submit()
                {

                  if (this.item_array.length==0)
                  {
                    this.alert.errorToastr("No, any item added for purchase order.");

                    return;
                  }

                  if(this.order_detail.purchaseOrder_date){
                    this.order_detail.purchaseOrder_date = moment(this.order_detail.purchaseOrder_date).format('YYYY-MM-DD')
                  }

                  console.log(this.item_data);
                  this.serve.fetchData({'item_detail':this.item_array,'vendor_data':this.order_detail,'uid':this.login_data.id,'total_qty':this.total_qty,'total_amount':this.total_amount, 'total_charges': this.charge},"Organization/add_purchase_order").subscribe((result=>
                    {
                      console.log(result);
                      window.history.go(-1)
                    }))

                  }

                  back()
                  {
                    window.history.go(-1)
                  }

                  get_purchase_detail_for_update(){

                    this.serve.fetchData({'id':this.id},"Organization/purchase_order_detail").subscribe((result=>
                      {
                        console.log(result);
                        this.order_detail=result;
                        console.log(this.order_detail);
                        this.charge.packing = this.order_detail.packing;
                        this.charge.sgst_amt = this.order_detail.sgst_amt;
                        this.charge.igst_amt = this.order_detail.igst_amt;
                        this.charge.total_items = this.order_detail.total_items;
                        this.charge.transport = this.order_detail.transport;
                        this.charge.other = this.order_detail.other;
                        this.order_detail.purchaseOrder_date = this.order_detail.PO_date;

                        this.charge.gst = this.order_detail.cgst?this.order_detail.cgst:this.order_detail.igst;
                        this.charge.gstType=this.order_detail.gstType;
                        this.grandTotal = this.order_detail.order_total;
                        this.order_detail.termsNcondition=this.order_detail.termsNcondition;
                        this.order_detail.vendor =(this.order_detail.vendor_id).toString();

                        // this.order_detail={

                        //   'vendor':result['vendor_id'],
                        //   'organization':result['org_id'],

                        // }
                        console.log(this.order_detail);


                        this.item_array = result['order_item'].map(val => { return val})
                        this.vendor_dealing_item(this.order_detail.vendor_id);
                        this.address(this.order_detail.vendor_id);
                        console.log(this.item_array);

                      }))


                    }

                    tmpsearch: any = {};
                    filter_dr(search) {
                      console.log("filter_dr method calls", search);
                      console.log(this.tmp_product_List);
                      this.item_list = [];
                      for (var i = 0; i < this.tmp_product_List.length; i++) {
                        search = search.toLowerCase();
                        const filterSearchBrand = this.tmp_product_List[i]['brand'].toLowerCase(); ''
                        const filterSearchCategory = this.tmp_product_List[i]['category'].toLowerCase(); ''
                        const filterSearcStockType = this.tmp_product_List[i]['item_name'].toLowerCase(); ''
                        const unit_of_measurment = this.tmp_product_List[i]['unit_of_measurment'].toLowerCase(); ''
                        if (filterSearchBrand.includes(search) || unit_of_measurment.includes(search)|| filterSearchCategory.includes(search) || filterSearcStockType.includes(search)) {
                          this.item_list.push(this.tmp_product_List[i]);
                        }
                      }
                    }


                    update_data(){
                      this.serve.fetchData({'purchase_id':this.id,'item_detail':this.item_array,'vendor_data':this.order_detail,'uid':this.login_data.id,'total_qty':this.total_qty,'total_amount':this.total_amount, 'total_charges': this.charge},"Organization/update_purchase_order").subscribe((result=>
                        {
                          console.log(result);
                          window.history.go(-1)
                        }))
                      }

                      calc_disc(){
                        // this.item_detail.discount = this.item_detail.discount?this.item_detail.discount:0;
                        this.item_detail.price = this.item_detail.price?this.item_detail.price:0;
                        this.item_detail.qty = this.item_detail.qty?this.item_detail.qty:0;
                        // this.item_detail.amount = this.item_detail.price - ((this.item_detail.discount/100)*this.item_detail.price);
                        this.item_detail.amount = this.item_detail.discount?((this.item_detail.price*this.item_detail.qty) - ((this.item_detail.discount/100)*(this.item_detail.price*this.item_detail.qty))):this.item_detail.price*this.item_detail.qty;
                      }
                    }
