import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { DistributionEditComponent } from '../distribution/distribution-edit/distribution-edit.component';
import * as moment from 'moment';
import { ImageModuleComponent } from '../image-module/image-module.component';

@Component({
  selector: 'app-karigar-detail',
  templateUrl: './karigar-detail.component.html',
  styleUrls: ['./karigar-detail.component.scss']
})
export class KarigarDetailComponent implements OnInit {
  loader;
  karigarDetail:any = {};
  karigar_id;
  view_tab: any = 'Basic_Info';
  subtab: any ='intiate';
  masterdatesearch:any={}
  karigarstatement:any=[];
  couponstatement:any=[];
  excel_data:any=[];
  excel_data2:any=[];
  databaseurl:any;
  today_date:Date;
  pagenumber:any=1;
  total_page:any; 
  constructor(public db: DatabaseService, public dialog: DialogComponent, public route: ActivatedRoute, public location: Location,public dialog2: MatDialog,) { 
  this.today_date=new Date();
    
    this.route.params.subscribe(params => {
      console.log(params);
      this.karigar_id = params.id;
      console.log(this.karigar_id);
      this.getKarigarDetail(this.karigar_id);
      this.databaseurl=this.db.myurl2
    });
    
    
  }
  
  ngOnInit() {
  }
  
  
  getKarigarDetail(id)
  {
    console.log(id);
    
    
    this.loader = true
    this.db.fetchData("", "Karigar/GET_KARIGAR_DETAIL/"+id).subscribe((result) => {
      console.log(result);
      this.karigarDetail = result['data'];
      this.loader = false
      
    }, error => {
      console.log("eror");
      this.dialog.error("Something went wrong");
      this.loader = false;
    })
  }

  get_statement(id,tab)
  {
    console.log(id);
    if (tab=='Account_Statement') {
      var tmpsubtab=this.subtab;
    }
    if(this.masterdatesearch.date)
    {
      this.masterdatesearch.date=moment(this.masterdatesearch.date).format('YYYY-MM-DD');  
      console.log(this.masterdatesearch.date)  
    }
     this.loader = true
    this.db.fetchData({'page':this.pagenumber,'date':this.masterdatesearch.date}, "Karigar/getstatementOfKarigar/"+tab+'/'+tmpsubtab+'/'+id).subscribe((result) => {
      console.log(result);
      this.karigarstatement = result['data'];
      this.loader = false
      this.total_page  = Math.ceil(parseInt(result['count'])/50);
      
    }, error => {
      console.log("eror");
      this.dialog.error("Something went wrong");
      this.loader = false;
    })
  }

  get_Coupond(id,tab)
  {
    console.log(id);
    console.log(`tab`, tab);
    
    this.loader = true
    this.db.fetchData("", "Karigar/getstatementOfKarigar/"+tab+'/'+'artisan/'+id).subscribe((result) => {
      console.log(result);
      this.couponstatement = result['data'];
      this.loader = false
      
    }, error => {
      console.log("eror");
      this.dialog.error("Something went wrong");
      this.loader = false;
    })
  }
  
  
  
  back(): void {
    console.log("location back method calss");
    console.log(this.location);
    this.location.back()
  }
  
  exportAsXLSXcoupan(): void {
    this.loader = 1;
    for (let i = 0; i < this.couponstatement.length; i++) {
      this.excel_data.push({ 'Coupon Code': this.couponstatement[i].coupon_code, 'Scan Date': this.couponstatement[i].scan_by_karigar_date,'Coupon Value': this.couponstatement[i].karigar_coupon_value,});
    }
    this.loader = '';
    this.db.exportAsExcelFile(this.excel_data, 'Artisan scan coupon list');
    this.excel_data=[];

  }
  // exportAsXLSXtxn(): void {
  //   this.loader = 1;
  //   for (let i = 0; i < this.karigarstatement.length; i++) {
  //     this.excel_data2.push({ 'Transaction Date': this.karigarstatement[i].transction_date, 'Transaction No.': this.karigarstatement[i].response?this.karigarstatement[i].response.UTRNUMBER:'N/A','Account No.': this.karigarstatement[i].request.CREDITACC?this.karigarstatement[i].request.CREDITACC:this.karigarstatement[i].request.bank_account_no,'IFSC Code': this.karigarstatement[i].request.IFSC?this.karigarstatement[i].request.IFSC:this.karigarstatement[i].request.bank_ifsc_code,'Account Holder Name': this.karigarstatement[i].request.PAYEENAME?this.karigarstatement[i].request.PAYEENAME:this.karigarstatement[i].request.account_holder_name,});
  //   }
  //   this.loader = '';
  //   this.db.exportAsExcelFile(this.excel_data2, 'Artisan Transaction detail');
  //   this.excel_data2=[];

  // }
  editAccDetail(bank_name, account_holder_name, account_type, bank_account_no, bank_ifsc_code, type) {
    const dialogRef = this.dialog2.open(DistributionEditComponent, {
        width: '768px',
        data: {
          bank_name,
          account_holder_name,
          account_type,
          bank_account_no,
          bank_ifsc_code,
          type,
          id: this.karigar_id
        }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getKarigarDetail(this.karigar_id);
      
  });
}

exportAsXLSXtxn(id,tab)
  {
    console.log(id);
    if (tab=='Account_Statement') {
      var tmpsubtab=this.subtab;
    }
     this.loader = true
    this.db.fetchDataWithBlob({}, "Karigar/getstatementOfKarigarCSV/Account_Statement/"+tmpsubtab+'/'+id).subscribe
    ((result) => {
      this.loader = false
      const blob = new Blob([result],{type:"text/csv"});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'transaction.csv';
      document.body.appendChild(a);
      a.click();
    console.log('harsh',result)
      // this.loader = false
      // window.open(this.databaseurl +'uploads/'+result['filename'])
    }, error => {
      console.log("eror",error);
      this.dialog.error("Something went wrong");
      this.loader = false;
    })
  }

  open_passbook_image(image){
    console.log('test');

    const dialogRef = this.dialog2.open(ImageModuleComponent, {
      width: '500px',
      data: {
        type: 'karigar_passbook',
        image:image 
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('The dialog was closed');

    });
  }

  open_document_image(image){
    console.log('test');

    const dialogRef = this.dialog2.open(ImageModuleComponent, {
      width: '500px',
      data: {
        type: 'karigar_docs',
        image:image
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('The dialog was closed');

    });
  }

  checkImageType(url:string){
console.log('hdd',url)
  }
}
