import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService';
import { DialogComponent } from '../dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-point-master',
  templateUrl: './point-master.component.html',
  styleUrls: ['./point-master.component.scss']
})
export class PointMasterComponent implements OnInit {

  loader = false;
  pointmaster:any={};
  constructor(public db: DatabaseService, public dialog: DialogComponent, public location: Location) { 

    this.getPointMaster();
  }

  ngOnInit() {
  }


  getPointMaster(){
    this.db.fetchData("", "Karigar/GET_POINT_MASTER").subscribe((result) => {
      console.log(result);
      this.pointmaster = result['data'];
      this.loader = false

    }, error => {
      console.log("eror");
      this.dialog.error("Something went wrong");
      this.loader = false;
    })
  }

  updatePointMaster() {
    this.db.fetchData(this.pointmaster, "Karigar/UPDATE_POINT_MASTER").subscribe((result) => {
      console.log(result);
      this.loader = false

      this.getPointMaster();

    }, error => {
      console.log("eror");
      this.dialog.error("Something went wrong");
      this.loader = false;
    })
  }

  back(): void {
    console.log("location back method calss");
    console.log(this.location);
    this.location.back()
  }


}
