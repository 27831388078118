import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/_services/DatabaseService';
import * as moment from 'moment';
import { ExpenseModalComponent } from '../expense-modal/expense-modal.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute,Router } from '@angular/router';
import { Location } from '@angular/common'
import { DialogComponent } from 'src/app/dialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';



@Component({
  selector: 'app-list-expense',
  templateUrl: './list-expense.component.html',
  styleUrls: ['./list-expense.component.scss']
})
export class ListExpenseComponent implements OnInit 
{
  expense_list:any=[];
  pagelimit:any=50; 
  search:any={};
  active_tab = 'Pending';
  tab:any='Local Conveyance';
  sub_tab:any ='Daily';
  data_not_found=false;
  loader:boolean=false;
  skelton:any;
  searchData: any;
  search_val: any = {}
  backButton: boolean = false;
  count_data:any={};
  exp_loader:any=false;
  excel_data:any=[];
  exp_data:any=[];
  total_amount:any=0;
  count_list:any;
  todayDate:any;
  is_multiple_approval=false;
  is_checked=false;
   userData:any;
   userId:any;
   userName:any;
  constructor(public serve:DatabaseService,public location: Location,  public navparams: ActivatedRoute,public dialog: MatDialog, public dialog2: DialogComponent, public toast:ToastrManager) 
  { 
    this.skelton = new Array(7);
    this.userData = JSON.parse(localStorage.getItem('st_user'));
    console.log(this.userData);
    this.userId=this.userData['data']['id'];
    console.log(this.userName);
    this.userName=this.userData['data']['name'];
    console.log(this.userId); 
    this.todayDate=new Date();
    console.log(this.todayDate);
    
  }

  ngOnInit() 
  {
    this.searchData = (this.navparams['params']['_value']);
    if (this.searchData.selectedUser && this.searchData.selectedDate) {
      this.backButton = true;
      console.log("in navparams");
      this.search.userName = this.searchData.selectedUser;
      this.search.dateCreated = this.searchData.selectedDate;
      
      this.expenseList();
      console.log(this.search.date_created);
      
    }

    this.expenseList();
  }

  refresh(){
    this.is_multiple_approval=false;
    this.is_checked=false
    this.approval_data=[]
    this.search = {}
    this.expenseList();


  }

  expenseList(action:any='')
  {
    this.total_amount=0
    if(action == "refresh")
    {
      this.search = {};   
    }
    // console.log(this.active_tab);
    // if(this.search.followup_date)
    // {
    //   this.search.followup_date=moment(this.search.followup_date).format('YYYY-MM-DD');    
    // }

    this.loader=true;

    if(this.search.dateCreated)
    {
    this.search.dateCreated = moment(this.search.dateCreated).format('YYYY-MM-DD');
    console.log(this.search.dateCreated);
    
    }
    if (this.tab=='Other') {
      this.sub_tab=''
    }
    else{
      this.sub_tab=this.sub_tab;

    }
    
    this.serve.fetchData({'start':this.expense_list.length,'pagelimit':this.pagelimit,'search':this.search,'expenseStatus':this.active_tab,'tab':this.tab,'localconvtype':this.sub_tab},"Expense/expense_list").subscribe((result=>
    {
      console.log(result);
      // this.expense_list=result;

      this.expense_list = result['expense_list']?result['expense_list']:result;
      // this.total_amount=result['total_amount']
      this.count_data=result['expense_count_data']?result['expense_count_data']:{};
      console.log(this.count_data);
      
      for (let i = 0; i < this.expense_list.length; i++) {
        this.total_amount+=parseInt(this.expense_list[i].totalAmt)
        console.log(`this.total_amount`, this.total_amount);
      }

      if(this.expense_list.length ==0)
      {
        this.data_not_found=true;
      } 
      else 
      {
        this.data_not_found=false;
      }

      setTimeout (() => {
        this.loader=false;
        
      }, 100);
    }))
   
    if(this.search.date_from)
    {
    this.search.date_from = moment(this.search.date_from).format('YYYY-MM-DD');
    console.log(this.search.date_from);
    console.log(this.todayDate);
    
    }
    if(this.search.date_to)
    {
    this.search.date_to = moment(this.search.date_to).format('YYYY-MM-DD');
    console.log(this.search.date_to )
    }
  }


  expModal(type,id)
  {
    const dialogRef = this.dialog.open(ExpenseModalComponent, {
      width:'400px',
      data:{
        type,
        id
        // data:this.detail
      }});
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
    this.expenseList();
      
        
      });
      
    }

    back(): void {
      console.log("in location back method");
      console.log(this.location);
      this.location.back()
    }

    exportAsXLSX():void
    {
      this.exp_loader = true;
      
      this.serve.fetchData({'search':this.search,'expenseStatus':this.active_tab,'tab':this.tab,'localconvtype':this.sub_tab},"expense/expense_list_for_excel")
      .subscribe(resp=>{
        console.log(resp);
        this.exp_data = resp['expense_list'];
        console.log(this.exp_data);
        
        for(let i=0;i<this.exp_data.length;i++)
        {
          this.excel_data.push({'Date':this.exp_data[i].dateCreated,'User Name':this.exp_data[i].userName,'Expence Id':'#CLM-'+this.exp_data[i].id,'Designation':this.exp_data[i].Designation,'Expense Type':this.exp_data[i].expenseType,'Amount':this.exp_data[i].totalAmt,'Senior Status':this.exp_data[i].seniorStatus,'A/C Status':this.exp_data[i].acStatus});
        }
        this.exp_loader = false;
        
        this.serve.exportAsExcelFile(this.excel_data, 'Expence-List');
        this.excel_data = [];
        this.exp_data = [];
      });
    }


multiple_approval(){
      this.is_multiple_approval=true;
      console.log(this.is_multiple_approval);
  }

approval_data:any=[]
OnApprovalRowselected(event,app_row){
console.log(app_row);
console.log(event);
console.log(event.target.checked);
if(event.target.checked)
{
 this.is_checked=true
    console.log('in if');
    this.approval_data.push(app_row);
    console.log(this.approval_data);
    
}

else
{
    let exist =this.approval_data.findIndex(row=>row.id==app_row.id);
    console.log(exist);
    if (exist != -1) 
    {
        this.approval_data.splice(exist,1)
    }
    
    console.log('in else unchecked');
}
console.log(this.approval_data);
if(this.approval_data.length==0){
    this.is_checked=false

}
}

Approve_selected_row(){
console.log(this.approval_data);
this.dialog2.confirm('You want to Approve Selected Data').then((result) => {
  console.log(result);
  if (result) {
    this.loader=true;
    this.serve.fetchData({'data':this.approval_data,'created_by_id':this.userId,'created_by_name':this.userName},"expense/updateExpenseStatus")
    .subscribe(resp=>{
      console.log(resp);
      if(resp['msg']=='success'){
        this.dialog2.success('Daily Expenses','Approved Successfully..')
       this.loader=false;
        this.is_multiple_approval=false;
        this.is_checked=false
        this.approval_data=[]
        this.expenseList();

      }
      else{
        this.toast.errorToastr("Something Went Wrong!...");
        this.loader=false;

      this.is_multiple_approval=false;
      this.is_checked=false
      this.approval_data=[]
      }
    });
  }
  else{
    this.is_multiple_approval=false;
    this.is_checked=false
    this.approval_data=[]
  }
  
})
}
}
