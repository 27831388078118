import { Component, Inject, OnInit } from '@angular/core';
import {  MAT_DIALOG_DATA} from '@angular/material';
import {  DatabaseService} from 'src/_services/DatabaseService';
import {  sessionStorage} from 'src/app/localstorage.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as moment from 'moment';

const now = new Date();

@Component({
  selector: 'app-scheme-add',
  templateUrl: './scheme-add.component.html',
  styleUrls: ['./scheme-add.component.scss']
})
export class SchemeAddComponent implements OnInit {
  scheme: any={};
  today_date: any;
  logIN_user: any;
  schemes: any={};
  multiple: any=[];
  loader:any=false;

  constructor(public serve: DatabaseService,public session: sessionStorage,public Toastr: ToastrManager) {
    this.today_date = new Date().toISOString().slice(0, 10);
    console.log(this.today_date);
    this.logIN_user = JSON.parse(localStorage.getItem('st_user'));
  }

  ngOnInit() {
     this.scheme.start_date = '';
    this.scheme.end_date = '';

  }
  submit() {
    console.log(this.scheme);
    //this.scheme.created_by = this.logIN_user.data.id;
    // console.log(this.scheme.uid);
    this.scheme.created_by = this.logIN_user.data.id;
    this.scheme.created_by_type = this.logIN_user.data.name;
    this.scheme.start_date = moment(this.scheme.date_from).format('YYYY-MM-DD');
    this.scheme.end_date = moment(this.scheme.date_to).format('YYYY-MM-DD');
    console.log(this.scheme);
    this.serve.fetchData({'scheme_data': this.scheme,'data':this.multiple}, "Organization/add_scheme").subscribe((result => {
      console.log(result);
      if ((result['msg']) == "success") {
        this.Toastr.successToastr("Scheme Added Successfully");
        window.history.go(-1);
      }
      else{
        this.Toastr.errorToastr("something went wrong");
      }
  }))
}
add_slab(){
  console.log("address are ");

 this.multiple.push(this.schemes)
 this.schemes={}



  }

  back()
  {
    window.history.go(-1)
  }
}
